import { createApi } from '@reduxjs/toolkit/dist/query/react'
import { INfsProfile } from 'api/datahub'
import { flow } from 'lodash/fp'
import { IOdataResult } from 'shared/contracts/IOdataResult'
import { tryAcquireAccessToken } from 'shared/services/auth'
import { AppState } from 'store/shared'
import { getRockefellerApiConfig } from 'store/system'
import { axiosBaseQuery } from './shared'

export const DatahubApiSliceKey = 'api.datahub'
export type DatahubApiReducerState = {
  [DatahubApiSliceKey]: ReturnType<typeof datahubApi.reducer>
}

const getDatahubApiBaseUrl = (state: AppState) => {
  const base = flow(getRockefellerApiConfig, (x) => x?.root)(state)
  const v1 = new URL('datahub', base)
  return v1.href
}

const getDatahubApiAuthToken = (state: AppState) => {
  const scopes = flow(getRockefellerApiConfig, (x) => x?.scopes)(state)

  if (!scopes) {
    throw new Error('')
  }

  return tryAcquireAccessToken(scopes)
}

export const datahubApi = createApi({
  reducerPath: DatahubApiSliceKey,
  baseQuery: axiosBaseQuery({
    getBaseUrl: (state) => getDatahubApiBaseUrl(state),
    getAuthToken: (state) => getDatahubApiAuthToken(state)
  }),
  tagTypes: ['profile'],
  endpoints: (builder) => ({
    fetchNfsProfileByWealthscapeId: builder.query<
      INfsProfile | undefined,
      string
    >({
      query: (id) => ({
        url: `/nfsProfiles`,
        params: {
          $filter: `wsportaluserid eq '${id}'`,
          $top: 1
        },
        headers: { 'api-version': '1.0' }
      }),
      transformResponse: (response: IOdataResult<INfsProfile>) =>
        response?.value?.[0],
      providesTags: (result) => [{ type: 'profile', id: result?.id }]
    })
  })
})
export const {
  useFetchNfsProfileByWealthscapeIdQuery,
  useLazyFetchNfsProfileByWealthscapeIdQuery
} = datahubApi
