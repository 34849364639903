import { lazy } from 'react'
import { combineReducers } from 'redux'
import { IModuleDefinition } from '../types'
import { ramReducer } from './store/reducer'
import { RAMsagas } from './store/sagas'

const RamContainer = lazy(() => import('./RamContainer'))

const moduleDefinition: IModuleDefinition = {
  routeProps: {
    path: '/ram/*',
    private: true,
    component: RamContainer
  },
  name: 'ram'
}

export default moduleDefinition

export const ramModuleReducer = combineReducers({
  dasboard: ramReducer
})

export const ramModuleSagas = [...RAMsagas]
