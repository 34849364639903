import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react'
import { DynamicsUserRolesEnum } from 'store/user/dynamicsUser'
import { RdotUserRoleEnum } from 'store/user/rdotUser'

export const RdotApiSliceKey = 'api.spa'
export type RdotApiReducerState = {
  [RdotApiSliceKey]: ReturnType<typeof rdotApi.reducer>
}

export type MenuListItem = {
  Key: string
  Name: string
  Type: string
  Url?: string
  Roles?: RdotUserRoleEnum[]
  CRMRoles?: DynamicsUserRolesEnum[]
}

export type MenuList = {
  Category: string
  Items: MenuListItem[]
}

export const rdotApi = createApi({
  reducerPath: RdotApiSliceKey,
  baseQuery: fetchBaseQuery({ baseUrl: '/' }),
  endpoints: (builder) => ({
    getNavigationConfiguration: builder.query<MenuList[], void>({
      query: () => `navigation-panel.json`,
      keepUnusedDataFor: 60 * 60 * 24
    })
  })
})

export const {
  useGetNavigationConfigurationQuery,
  usePrefetch: useRdotApiPrefetch
} = rdotApi
