import { initializeIcons, makeStyles, ThemeProvider } from '@fluentui/react'
import React, {
  PropsWithChildren,
  StrictMode,
  Suspense,
  useEffect
} from 'react'
import { createRoot } from 'react-dom/client'
import { IntlProvider } from 'react-intl'
import { Provider, useSelector } from 'react-redux'
import {
  Navigate,
  Route,
  Routes,
  unstable_HistoryRouter as HistoryRouter,
  useLocation
} from 'react-router-dom'
import { Store } from 'redux'
import { registerInterceptors } from 'shared/services/axios'
import { PrivateRoute } from './features/PrivateRoute'
import modules from './modules'
import * as serviceWorker from './serviceWorker'
import { ErrorBoundary } from './shared/components/ErrorBoundary'
import { NotFound } from './shared/components/NotFound'
import { ready } from './shared/services/dom'
import { getEnvironment } from './shared/services/environment'
import { history } from './shared/services/history'
import { createReduxStore } from './store'
import { getIsEnvironmentLoaded, systemActions } from './store/system'
import { getTheme } from './store/ui/selectors'
import './styles/index.css'

const AllRoutes: React.FC = () => {
  const isEnvironmentReady = useSelector(getIsEnvironmentLoaded)

  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  if (!isEnvironmentReady) {
    return <div className="spa-login-splash" />
  }

  return (
    <Routes>
      {modules.map(({ routeProps, name }) => (
        <Route
          path={routeProps.path}
          element={<PrivateRoute routeProps={routeProps} />}
          key={name}
        />
      ))}
      <Route
        path="/dashboard"
        element={<Navigate to="/advisor/dashboard" replace />}
      />
      <Route path="/*" element={<NotFound />} />
    </Routes>
  )
}

const useBodyStyles = makeStyles((theme) => {
  return {
    body: {
      background: theme.palette.neutralLighter,
      color: theme.semanticColors.bodyText,
      ...theme.fonts.medium,
      '@media print': {
        backgroundColor: theme.palette.white
      }
    }
  }
})

const ConnectedThemeProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const theme = useSelector(getTheme)
  const classes = useBodyStyles({ theme })

  useEffect(() => {
    const body: HTMLBodyElement = document.getElementsByTagName('body')[0]
    const doc = document.documentElement

    body.className = classes.body
    doc.className = classes.body
  }, [classes])

  return (
    <ThemeProvider theme={theme} applyTo="none">
      {children}
    </ThemeProvider>
  )
}
const Panels: React.FC = () => {
  const isEnvironmentReady = useSelector(getIsEnvironmentLoaded)
  return isEnvironmentReady ? <></> : null
}

const Root: React.FC<{ store: Store }> = ({ store }) => {
  return (
    <StrictMode>
      <Provider store={store}>
        <ConnectedThemeProvider>
          <HistoryRouter history={history}>
            <ErrorBoundary>
              <IntlProvider locale="en">
                <Suspense fallback={<div />}>
                  <AllRoutes />
                </Suspense>
                <Panels />
              </IntlProvider>
            </ErrorBoundary>
            {/* <NotificationBanner /> */}
          </HistoryRouter>
        </ConnectedThemeProvider>
      </Provider>
    </StrictMode>
  )
}

const init = async () => {
  const store = createReduxStore()

  if (process.env.NODE_ENV === 'development') {
    const { startMockApi } = await import('./mocks')
    await startMockApi()
  }
  registerInterceptors()

  getEnvironment().then((config) =>
    store.dispatch(systemActions.setEnvironment(config))
  )

  const container = document.getElementById('root')
  if (!container) {
    throw new Error('Failed to get reference to container element')
  }
  const root = createRoot(container)

  ready().then(() => root.render(<Root store={store} />))

  initializeIcons()
}

init()

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
