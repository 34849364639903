import axios, { AxiosError } from 'axios'
import { trim } from 'lodash'
import { flow } from 'lodash/fp'
import { IApiOptions } from '../shared/contracts/IApiOptions'
import { IAccount } from './account.types'
import { IAdvisorDetailResult } from './advisor.types'
import {
  ISearchParams,
  ISearchResult,
  SearchIndices,
  SearchResponseType
} from './common.types'
import { IServicePrincipal } from './graph'
import { constructFilterQuery } from './odata'
import { IOrder } from './order.types'
import { IPosition } from './position.types'
import { ISecurity } from './security.types'
import { ISPAError } from './spaerror.types'

export const queryEscapeRegex = /([!*+&|()[\]{}^~?:"%#/<>\\`;=$-])/g
export const escapeAndEncodeQuery: (query: string) => string =
  // doesn't look like special chars get indexed, so lets just remove them
  // query.replace(queryEscapeRegex, '\\$1')
  flow(
    (x) => x.replace(queryEscapeRegex, ' '),
    trim,
    (x) => x.split(/ +/).filter(Boolean).map(encodeURIComponent).join('+')
  )

// export const escapeFilterValue = (filterValue: string) => {

// attribute = attribute.replace(/'/g, "''");

//      attribute = attribute.replace(/"+"/g, "%2B");
//      attribute = attribute.replace(/\//g, "%2F");
//      attribute = attribute.replace(/"?"/g, "%3F");
//      attribute = attribute.replace(/%/g, "%25");
//      attribute = attribute.replace(/#/g, "%23");
//      attribute = attribute.replace(/&/g, "%26");
// }

export const tokenizeQuery = (text = '') =>
  text.replace(queryEscapeRegex, ' ').split(/[ ,]+/).map(trim).filter(Boolean)

export const operators = {
  and: `+AND+`,
  or: `+OR+`
}

export const constructQuery = (query: string) =>
  tokenizeQuery(query)
    .map(escapeAndEncodeQuery)
    .filter(Boolean)
    .map((x) => `${x}*`)
    .join(operators.and)

export const constructOdataQueryFromSearchParams = (params: ISearchParams) => {
  const query = params.query
    ? params.exact
      ? escapeAndEncodeQuery(params.query)
      : constructQuery(params.query)
    : null

  const searchFilter = params.searchFilters
    ? params.searchFilters
        .map((filter) => `${filter.dataPath}:(${constructQuery(filter.query)})`)
        .join(operators.and)
    : null

  const searchText = [searchFilter, query].filter(Boolean).join(operators.and)

  return [
    searchText ? `search=${searchText}` : null,
    params.fullQuery ? 'queryType=full' : null,
    params.searchFields && params.searchFields.length && query && query.length
      ? `searchFields=${params.searchFields.map(encodeURIComponent).join(',')}`
      : null,
    params.select && params.select.length
      ? `select=${params.select.map(encodeURIComponent).join(',')}`
      : null,
    params.orderBy && params.orderBy.length
      ? `$orderby=${params.orderBy
          .map((x) => [x.dataPath, x.direction].filter((y) => y).join(' '))
          .join(',')}`
      : null,
    params.count ? '$count=true' : null,
    params.skip ? `$skip=${params.skip}` : null,
    `$top=${params.top ?? 1000}`,
    params.filters?.length
      ? `$filter=${constructFilterQuery(params.filters)}`
      : null,
    params.facets?.length
      ? params.facets.map((x) => `facet=${encodeURIComponent(x)}`).join('&')
      : null
  ]
    .filter(Boolean)
    .join('&')
}

export function search(
  index: 'account',
  params: ISearchParams,
  options: IApiOptions
): Promise<ISearchResult<IAccount>>
export function search(
  index: 'client',
  params: ISearchParams,
  options: IApiOptions
): Promise<ISearchResult<any>>
export function search(
  index: 'advisor',
  params: ISearchParams,
  options: IApiOptions
): Promise<ISearchResult<IAdvisorDetailResult>>
export function search(
  index: 'applications',
  params: ISearchParams,
  options: IApiOptions
): Promise<ISearchResult<IServicePrincipal>>
export function search(
  index: 'position',
  params: ISearchParams,
  options: IApiOptions
): Promise<ISearchResult<IPosition>>
export function search(
  index: 'order',
  params: ISearchParams,
  options: IApiOptions
): Promise<ISearchResult<IOrder>>
export function search(
  index: 'home',
  params: ISearchParams,
  options: IApiOptions
): Promise<ISearchResult<any>>
export function search(
  index: 'security',
  params: ISearchParams,
  options: IApiOptions
): Promise<ISearchResult<ISecurity>>
export function search(
  index: 'spaerror',
  params: ISearchParams,
  options: IApiOptions
): Promise<ISearchResult<ISPAError>>
export function search<T extends SearchResponseType>(
  index: SearchIndices,
  params: ISearchParams,
  options: IApiOptions
): Promise<ISearchResult<T>>
export function search(
  index: SearchIndices,
  params: ISearchParams,
  options: IApiOptions
): Promise<ISearchResult<SearchResponseType>> {
  let apiPath = ''
  switch (index) {
    case 'account':
      apiPath = 'accounts'
      break
    case 'advisor':
      apiPath = 'advisors'
      break
    case 'client':
      apiPath = 'clients'
      break
    case 'position':
      apiPath = 'positions'
      break
    case 'order':
      apiPath = 'orders'
      break
    case 'home':
      apiPath = 'households'
      break
    case 'creditevent':
      apiPath = 'creditevents'
      break
    case 'security':
      apiPath = 'security'
      break
    case 'spaerror':
      apiPath = 'spaerror'
      break
    case 'umataxlots':
      apiPath = 'umataxlots'
      break
    case 'umasleeves':
      apiPath = 'umasleeves'
      break
  }

  const rootPath = `${options.apiRoot}/datahub/search/${apiPath}`
  const queryString = constructOdataQueryFromSearchParams({
    ...params,
    fullQuery: true
  })
  const url = `${rootPath}?${queryString}`

  return axios
    .get<ISearchResult<SearchResponseType>>(`${url}`, {
      headers: { Authorization: `Bearer ${options.accessToken}` },
      cancelToken: options.cancelToken
    })
    .then((x) => {
      if (!x || !x.data) {
        throw new Error('Invalid response from the search API')
      }

      if (!x.data.value) {
        throw new Error('No search result value was returned')
      }

      return x.data
    })
    .catch((e) => {
      const axiosError = e as AxiosError<{ error?: Error }>
      const message = axiosError?.response?.data?.error?.message
      if (message) {
        throw new Error(message)
      }

      throw axiosError
    })
}

export function safeSearch(
  index: SearchIndices,
  params: ISearchParams,
  options: IApiOptions
) {
  return search(index, params, options).catch(() => [])
}
