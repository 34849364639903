import { push, replace } from 'connected-react-router'
import { trim } from 'lodash'
import { parse, stringify } from 'query-string'
import { isNotNullOrEmpty } from 'shared/gaurds'
import { getLocation } from 'shared/services/dom'
import { call, put, select, takeLatest } from 'typed-redux-saga'
import { constructListsUiUrlSearch } from '../../../../../features/Lists/shared/lists'
import {
  dashboardActions,
  dashboardNavigationActions,
  TOGGLE_NET_NEW_MONEY_SELECTED
} from './actions'
import {
  getIsNetNewAssetsSelected,
  getIsNetNewMoneySelected
} from './selectors'

export const getDashboardRouteState = () => {
  const { search } = getLocation()
  const { list = 'household', reps = '' } = parse(search, {
    arrayFormat: 'none'
  })

  const parsedReps = (reps as string | undefined)
    ?.split(',')
    .map(trim)
    .filter(isNotNullOrEmpty)
    .sort()

  return { list: list as string, reps: parsedReps }
}

export const dashboardSagas = [
  () =>
    takeLatest(
      dashboardNavigationActions.navigateToDashboard,
      function* (
        action: ReturnType<
          typeof dashboardNavigationActions.navigateToDashboard
        >
      ) {
        const { pathname } = getLocation()

        const currentRouteState = yield* call(getDashboardRouteState)

        const {
          list = currentRouteState.list,
          reps = currentRouteState.reps,
          listState
        } = action.payload || {}

        const shouldReplace =
          !pathname ||
          pathname === '/' ||
          (pathname === '/advisor/dashboard' &&
            (!currentRouteState.list || !currentRouteState.reps?.length))

        const newState = {
          ...{ list, reps },
          ...(listState ? constructListsUiUrlSearch(listState) : {})
        }

        const newSearch = stringify(newState, {
          encode: false,
          arrayFormat: 'comma'
        })

        const routeAction = shouldReplace ? replace : push

        yield put(
          routeAction({ search: newSearch, pathname: '/advisor/dashboard' })
        )
      }
    ),
  () =>
    takeLatest(
      [
        dashboardActions.toggleNetNewAssetsSelected,
        dashboardActions.toggleNetNewMoneySelected
      ],
      function* (
        action:
          | ReturnType<typeof dashboardActions.toggleNetNewAssetsSelected>
          | ReturnType<typeof dashboardActions.toggleNetNewMoneySelected>
      ) {
        const { list: listType } = yield* call(getDashboardRouteState)
        const isPriorDayNewAssetsSelected = yield* select(
          getIsNetNewAssetsSelected
        )
        const isPriorDayNewMoneySelected = yield* select(
          getIsNetNewMoneySelected
        )

        if (isPriorDayNewAssetsSelected && isPriorDayNewMoneySelected) {
          const toggle =
            action.type === TOGGLE_NET_NEW_MONEY_SELECTED
              ? dashboardActions.toggleNetNewAssetsSelected
              : dashboardActions.toggleNetNewMoneySelected
          yield put(toggle())
        }

        if (
          (isPriorDayNewAssetsSelected || isPriorDayNewMoneySelected) &&
          !(
            listType === 'account' ||
            listType === 'client' ||
            listType === 'household'
          )
        ) {
          yield put(
            dashboardNavigationActions.navigateToDashboard({
              list: 'household'
            })
          )
        }
      }
    ),
  () =>
    takeLatest(
      dashboardActions.setSelectedPositions,
      function* (
        action: ReturnType<typeof dashboardActions.setSelectedPositions>
      ) {
        if (!action.payload?.length) {
          return
        }
        const { list: listType } = yield* call(getDashboardRouteState)
        if (listType !== 'position') {
          yield put(
            dashboardNavigationActions.navigateToDashboard({
              list: 'position'
            })
          )
        }
      }
    )
]
