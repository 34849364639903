import { format } from 'date-fns'
import { difference, get, keyBy, range, uniq } from 'lodash'
import { partial } from 'lodash/fp'
import { StrictEffect } from 'redux-saga/effects'
import {
  all,
  call,
  delay,
  put,
  select,
  take,
  takeLatest
} from 'typed-redux-saga'
import {
  IFacetResult,
  ISearchFilter,
  ISearchParams,
  ISearchResult,
  SearchIndices,
  SearchResponseType
} from '../../../../api/common.types'
import {
  IOdataCollectionFilter,
  IOdataPropertyFilter,
  OdataFilterCollectionOperatorEnum,
  OdataFilterOperatorEnum,
  OdataPropertyFilterGroup
} from '../../../../api/odata'
import { IOrderBy, OrderByDirection } from '../../../../api/odata.types'
import {
  convertDateRangeToDates,
  DateRanges,
  parseDateISOStringInLocalTimezone
} from '../../../../shared/dates'
import { isNotNullOrEmpty, isNotNullOrFalse } from '../../../../shared/gaurds'
import { exportDataToExcel } from '../../../../shared/xlsx'
import { search as searchSaga } from '../../../../store/shared/sagas'
import { IColumnDefinition } from '../contracts/IColumnDefinition'
import {
  IColumnDataPathMapping,
  IExportConfiguration,
  IExportDefinition
} from '../contracts/IExportDefinition'
import {
  IListsDateRangeFilter,
  IListsFacetFilter,
  IListsFilter,
  IListsNumberRangeFilter,
  IListsSearchFilter
} from '../contracts/IListsFilter'
import { IListsFilterDefinition } from '../contracts/IListsFilterDefinition'
import { IListsUiState } from '../contracts/IListsUIState'
import {
  IListsActions,
  IListsDataActions,
  ListsExportActions,
  ListsFacetActions
} from './actions'
import {
  IListsDataSelectors,
  IListsSelectors,
  ListsUiSelectors
} from './selectors'

const initialChunkSize = 50
const chunkSize = 200
const getUtcDateStartOfDay = (date: Date) =>
  new Date(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0)
  )

export type OmitFirstArg<F> = F extends (x: any, ...args: infer P) => infer R
  ? (...args: P) => R
  : never

export const throttle = function* <T>(
  actions: (() => Generator<StrictEffect, T, unknown>)[],
  limit: number
) {
  const copy = [...actions]
  const doNextAction = function* (): Generator<StrictEffect, T[], unknown> {
    const action = copy.shift()
    if (!action) {
      return []
    }
    const current = yield* call(action)
    const next = yield* call(doNextAction)
    return [current, ...next]
  }
  const results = yield* all(range(0, limit).map(doNextAction))
  return results.flat()
}

export const createGetRequestParams = (uiSelectors: ListsUiSelectors) =>
  function* () {
    const uiState = yield* select(uiSelectors.getUiState)
    return getRequestParamsFromState(uiState)
  }

const getRequestParamsFromState = (uiState: IListsUiState) => {
  const { columnDefinitions, columnState, filters, searchText, orderBy } =
    uiState

  const searchOrderBy: IOrderBy[] = [
    !!orderBy && {
      dataPath: columnDefinitions?.[orderBy.columnId]?.dataPath as string,
      direction: orderBy.direction || 'asc'
    },
    !!searchText &&
      ({
        dataPath: 'search.score()',
        direction: 'desc'
      } as IOrderBy),
    {
      dataPath: 'id',
      direction: 'desc' as OrderByDirection
    }
  ].filter(isNotNullOrFalse)

  const selectNonUnique = (columnState || [])
    .filter((x) => x.selected)
    .map((x) => columnDefinitions?.[x.columnId])
    .map((x) => (x?.select ? x.select : [x?.collectionPath || x?.dataPath]))
    .reduce((a, x) => a.concat(x), [])
    .filter((x) => x) as string[]

  const selectSet = new Set(selectNonUnique)
  const isSearchFilter = (filter: IListsFilter) =>
    !filter.blankIndicator &&
    filter.type === 'search' &&
    (filter as IListsSearchFilter).filterType === 'search'
  const filterList = Object.entries(filters || {})
    .map(([, filter]) => filter)
    .filter((x) => x.hasValue)

  const params: ISearchParams = {
    top: 50,
    exact: false,
    orderBy: searchOrderBy,
    query: searchText,
    searchFields: uniq(
      (columnState || [])
        .filter((x) => x.includeInSearch && x.selected)
        .map((x) => {
          const column = columnDefinitions?.[x.columnId]
          return (
            column?.searchFields || [
              [column?.collectionPath, column?.dataPath]
                .filter(Boolean)
                .join('/')
            ]
          )
        })
        .flatMap((x) => x)
        .filter(isNotNullOrEmpty)
    ),
    select: [...selectSet],
    searchFilters: filterList.filter(isSearchFilter).map((x) => {
      const filter = x as IListsSearchFilter
      const searchFilter: ISearchFilter = {
        dataPath: [x.collectionPath, x.dataPath].filter(Boolean).join('/'),
        query: filter.value || ''
      }
      return searchFilter
    }),
    // positions/any(position: position/SecurityId eq 'AAGIY')
    // TODO - negate and allow and/or
    filters: filterList
      .filter((x) => !isSearchFilter(x))
      .map((filter) => {
        const { type, dataPath, collectionPath, blankIndicator } = filter

        if (!dataPath) {
          throw new Error('Invalid filter')
        }

        let group: OdataPropertyFilterGroup | null = null

        if ((type === 'date' || type === 'date-only') && !blankIndicator) {
          const { range, from, to } = filter as IListsDateRangeFilter

          if (!range) {
            throw new Error('Must specify a date range')
          }

          const [a, b] = (
            range === DateRanges.Custom
              ? [from, to]
              : convertDateRangeToDates(range)
          ).map(
            (x) => x && (type === 'date-only' ? getUtcDateStartOfDay(x) : x)
          )

          const base: IOdataPropertyFilter = {
            operator: OdataFilterOperatorEnum.ge,
            type: 'datetime',
            path: dataPath
          }

          group = {
            and: [
              a && {
                ...base,
                operator: OdataFilterOperatorEnum.ge,
                value: a
              },
              b && { ...base, operator: OdataFilterOperatorEnum.le, value: b }
            ].filter(Boolean) as IOdataPropertyFilter[]
          }
        }

        if (type === 'number' && !blankIndicator) {
          const {
            min,
            max,
            filterType = 'range',
            value
          } = filter as IListsNumberRangeFilter
          const base: IOdataPropertyFilter = {
            operator: OdataFilterOperatorEnum.ge,
            type: 'number',
            path: dataPath
          }

          group =
            filterType === 'range'
              ? {
                  and: [
                    min != null && {
                      ...base,
                      operator: OdataFilterOperatorEnum.ge,
                      value: min
                    },
                    max != null && {
                      ...base,
                      operator: OdataFilterOperatorEnum.le,
                      value: max
                    }
                  ].filter(Boolean) as IOdataPropertyFilter[]
                }
              : {
                  and: [
                    {
                      ...base,
                      operator: filterType as OdataFilterOperatorEnum,
                      value
                    }
                  ]
                }
        }

        if ((type === 'facet' || type === 'facet-search') && !blankIndicator) {
          const { values } = filter as IListsFacetFilter

          if (!values?.length) {
            return
          }

          group = {
            and: [
              {
                operator: OdataFilterOperatorEnum.searchin,
                path: dataPath,
                type: 'string',
                value: values
              }
            ]
          }
        }

        if (type === 'search' && !blankIndicator) {
          const { value, filterType } = filter as IListsSearchFilter
          let values: string[] | undefined
          value?.includes(',')
            ? (values = value.replace(/\s+/g, '').split(','))
            : (values = undefined)
          group =
            filterType === 'search'
              ? {
                  and: [
                    {
                      operator: OdataFilterOperatorEnum.search,
                      path: dataPath,
                      type: 'string',
                      value: value
                    }
                  ]
                }
              : values
              ? {
                  and: [
                    {
                      operator: OdataFilterOperatorEnum.searchin,
                      path: dataPath,
                      type: 'string',
                      value: values
                    }
                  ]
                }
              : {
                  and: [
                    {
                      operator: OdataFilterOperatorEnum.eq,
                      path: dataPath,
                      type: 'string',
                      value: value
                    }
                  ]
                }
        }

        if (blankIndicator) {
          group = {
            and: [
              {
                operator:
                  blankIndicator === 'exclude'
                    ? OdataFilterOperatorEnum.ne
                    : OdataFilterOperatorEnum.eq,
                path: dataPath,
                type:
                  type === 'date' || type === 'number' || type === 'date-only'
                    ? 'number'
                    : 'string',
                value: null
              }
            ]
          }
        }

        if (collectionPath && group) {
          const collectionFilter: IOdataCollectionFilter = {
            filter: group,
            operator: OdataFilterCollectionOperatorEnum.any,
            path: collectionPath
          }

          return collectionFilter
        }

        return group
      })
      .filter(Boolean) as (OdataPropertyFilterGroup | IOdataCollectionFilter)[]
  }

  return params
}

const createLoadMore = <T extends SearchResponseType>(
  getRequestParams: ReturnType<typeof createGetRequestParams>,
  search: OmitFirstArg<typeof searchSaga>,
  dataSelectors: IListsDataSelectors<T>,
  dataActions: IListsDataActions<T>
) =>
  function* () {
    const baseRequestParams = yield* call(getRequestParams)
    const chunks = yield* select(dataSelectors.getChunks)

    if (!chunks?.length) {
      throw new Error('Invalid State: chunks are undefined')
    }

    const currentChunkIndex = chunks.length - 1

    const totalCount = chunks[0]['@odata.count'] || 0

    const skip = currentChunkIndex * chunkSize + initialChunkSize
    const top = chunkSize

    if (skip > totalCount) {
      yield put(dataActions.complete(baseRequestParams))
      return
    }

    try {
      const chunk = yield* call(search, {
        ...baseRequestParams,
        top,
        skip
      })

      yield put(
        dataActions.chunk({
          index: currentChunkIndex + 1,
          result: chunk as ISearchResult<T>
        })
      )
    } catch (e: any) {
      yield put(dataActions.error(e))
    }

    yield put(dataActions.complete(baseRequestParams))
  }

const createChunkResults = <T extends SearchResponseType>(
  getRequestParams: ReturnType<typeof createGetRequestParams>,
  search: OmitFirstArg<typeof searchSaga>,
  uiSelectors: ListsUiSelectors,
  dataActions: IListsDataActions<T>
) =>
  function* () {
    console.debug('search requested')
    yield delay(300)

    let baseRequestParams: ISearchParams | undefined
    let totalCount = 0

    try {
      baseRequestParams = yield* call(getRequestParams)
      console.debug('search executing', baseRequestParams)
      const peekResults = yield* call(search, {
        ...baseRequestParams,
        top: initialChunkSize,
        count: true
      })

      yield put(
        dataActions.chunk({ index: 0, result: peekResults as ISearchResult<T> })
      )

      totalCount = peekResults['@odata.count'] || 0
    } catch (e: any) {
      yield put(dataActions.error(e))
    }

    const listSizeFromState = yield* select(uiSelectors.getListSize)
    let listSize =
      listSizeFromState == null
        ? initialChunkSize
        : listSizeFromState === 0
        ? totalCount
        : listSizeFromState

    listSize = Math.min(listSize, totalCount)

    if (listSize > initialChunkSize) {
      const iterations = Math.ceil((listSize - initialChunkSize) / chunkSize)

      yield delay(10)

      const chunks = Array(iterations)
        .fill(1)
        .map((_, i) => i)
        .map(
          (i) =>
            function* () {
              const skip = i * chunkSize + initialChunkSize
              let top = chunkSize

              if (skip + top > listSize) {
                top = listSize - skip
              }

              const chunk = yield* call(search, {
                ...baseRequestParams,
                top,
                skip
              })
              yield put(
                dataActions.chunk({
                  index: i + 1,
                  result: chunk as ISearchResult<T>
                })
              )
            }
        )

      try {
        yield all(chunks.map(call))
      } catch (e: any) {
        yield put(dataActions.error(e))
      }
    }

    yield put(dataActions.complete(baseRequestParams))
  }

const getMapping = (
  columnDefinition: IColumnDefinition
): IColumnDataPathMapping => ({
  type: columnDefinition.type,
  collectionPath: columnDefinition.collectionPath?.split('/'),
  path: columnDefinition.dataPath?.split('/')
})

const getValue = <T>(
  item: T,
  mapping: IColumnDataPathMapping
): string | number | boolean | undefined => {
  const { collectionPath } = mapping
  if (collectionPath) {
    const collection: any[] = get(item, collectionPath)
    return collection && collection.length
      ? collection
          .slice(0, 50)
          .map((y) => getValue(y, { ...mapping, collectionPath: undefined }))
          .filter(Boolean)
          .join(', ')
      : ''
  }

  const { path } = mapping
  const value = path ? get(item, path) : ''
  if (mapping.type === 'date' || mapping.type === 'date-only') {
    return value
      ? format(
          mapping.type === 'date-only'
            ? parseDateISOStringInLocalTimezone(value)
            : new Date(value),
          'MM/dd/yyyy'
        )
      : ''
  }

  return value
}

const createExport = <T extends SearchResponseType>(
  getRequestParams: ReturnType<typeof createGetRequestParams>,
  search: OmitFirstArg<typeof searchSaga>,
  exportActions: ListsExportActions,
  uiSelectors: ListsUiSelectors,
  exportConfigurations?: Record<string, IExportConfiguration<T>>
) =>
  function* () {
    yield delay(300)
    const chunks: {
      index: number
      result: ISearchResult<T>
    }[] = []
    const baseRequestParams = yield* call(getRequestParams)
    let totalCount = 0

    try {
      const peekResults = yield* call(search, {
        ...baseRequestParams,
        top: initialChunkSize,
        count: true
      })
      chunks.push({ index: 0, result: peekResults as ISearchResult<T> })

      totalCount = peekResults['@odata.count'] || 0
    } catch (e: any) {
      alert('An error occurred while exporting, please try again.')
      yield put(exportActions.error(e))
    }

    if (totalCount > initialChunkSize) {
      const iterations = Math.ceil((totalCount - initialChunkSize) / chunkSize)

      const actions = Array(iterations)
        .fill(1)
        .map((_, i) => i)
        .map(
          (
            i
          ): (() => Generator<
            StrictEffect,
            { index: number; result: ISearchResult<T> },
            unknown
          >) =>
            function* () {
              const skip = i * chunkSize + initialChunkSize
              let top = chunkSize

              if (skip + top > totalCount) {
                top = totalCount - skip
              }

              const chunk = yield* call(search, {
                ...baseRequestParams,
                top,
                skip
              })
              return { index: i + 1, result: chunk as ISearchResult<T> }
            }
        )

      try {
        const results = yield* throttle(actions, 5)
        chunks.push(...results)
      } catch (e: any) {
        yield put(exportActions.error(e))
      }
    }

    const data = chunks
      .sort((a, b) => a.index - b.index)
      .reduce((a, x) => a.concat(x.result.value), [] as T[])

    const columnDefinitions = yield* select(uiSelectors.getColumnDefinitions)
    const columnDefinitionLookup = keyBy(columnDefinitions, (x) => x.id)
    const columnState = yield* select(uiSelectors.getColumnState)

    const selectedColumns = columnState
      .filter((x) => x.selected)
      .map((x) => columnDefinitionLookup[x.columnId])

    const isExportDefinition = (
      input: IExportDefinition<T> | IColumnDefinition
    ): input is IExportDefinition<T> => {
      return (input as IExportDefinition<T>)?.columnId != null
    }

    const exportColumns = selectedColumns
      .map((column): IExportDefinition<T>[] => {
        const exportConfiguration = exportConfigurations?.[column.id]

        const getExportDefinition = (
          definition: IColumnDefinition
        ): IExportDefinition<T> => ({
          columnId: definition.id,
          columnName: definition.name,
          getValue: (item) => getValue(item, getMapping(definition))
        })

        const mapping = getMapping(column)
        if (exportConfiguration) {
          return exportConfiguration
            .getExportColumns(data, column)
            .map((x) =>
              isExportDefinition(x)
                ? { ...x, dataMapping: mapping }
                : getExportDefinition(x)
            )
        }

        return [getExportDefinition(column)]
      })
      .flat()

    const headers = exportColumns.map((x) => x?.columnName)

    try {
      const mappedData = yield* all(
        data.map(function* (item) {
          const results = exportColumns.map(function* (column) {
            const value = yield* column.getValue
              ? call(column.getValue, item, data)
              : column.getValueGenerator
              ? call(column.getValueGenerator, item)
              : call(() => Promise.resolve())

            if (value === undefined && column.dataMapping) {
              return getValue(item, column.dataMapping)
            }
            return typeof value === 'object' ? (value || '').toString() : value
          })

          return yield* all(results)
        })
      )

      mappedData.unshift(headers)

      const filename = 'Export.xlsx'
      const wsName = 'Export'
      yield call(
        exportDataToExcel,
        {
          sheets: [{ name: wsName, data: mappedData }]
        },
        filename
      )
    } catch (e: any) {
      yield put(exportActions.error(e))
    }

    yield put(exportActions.complete())
  }

export const createFacet = (
  search: OmitFirstArg<typeof searchSaga>,
  uiSelectors: ListsUiSelectors,
  facetActions: ListsFacetActions
) =>
  function* (action: ReturnType<typeof facetActions.request>) {
    yield delay(300)
    const uiState = yield* select(uiSelectors.getUiState)

    const getSearchPath = (filter: IListsFilterDefinition) =>
      [filter.collectionPath, filter.dataPath].filter(Boolean).join('/')

    const { id, searchText } = action.payload

    const filterDefinitions = yield* select(uiSelectors.getFilterDefinitions)
    let searchFilters: ISearchFilter[] = []

    let facetableFilters = Object.entries(filterDefinitions || {})
      .filter(
        ([, value]) => value.type === 'facet' || value.type === 'facet-search'
      )
      .map(([, value]) => value)

    const modifiedState = { ...uiState }

    if (id && filterDefinitions?.[id]) {
      const modifiedFilters: Record<string, IListsFilter> = {
        ...modifiedState.filters
      }
      delete modifiedFilters[id]
      modifiedState.filters = modifiedFilters
      const filterDefinition = filterDefinitions[id]
      facetableFilters = [filterDefinition]
      searchFilters =
        (searchText &&
          filterDefinition && [
            {
              dataPath: getSearchPath(filterDefinition),
              query: searchText
            }
          ]) ||
        []
    }

    const baseRequestParams = yield* call(
      getRequestParamsFromState,
      modifiedState
    )

    try {
      const peekResults = yield* call(search, {
        ...baseRequestParams,
        top: 0,
        count: false,
        searchFilters: [
          ...(baseRequestParams.searchFilters || []),
          ...searchFilters
        ],
        facets: facetableFilters.map((x) => `${getSearchPath(x)},count:1000`)
      })

      const facetMap = keyBy(facetableFilters, (x) => getSearchPath(x))
      const resultFacets = peekResults['@search.facets']

      if (!resultFacets) {
        throw new Error('No facets returned from service')
      }

      const facetRecords: Record<string, IFacetResult[]> = Object.keys(
        resultFacets
      )
        .map((x) => {
          return {
            facetId: facetMap[x].id,
            facets: resultFacets[x]
          }
        })
        .reduce((a, x) => ({ ...a, [x.facetId]: x.facets }), {})

      yield put(facetActions.complete(facetRecords))
    } catch (e: any) {
      console.error(e)
      yield put(facetActions.error(e))
    }
  }

export const createListsSagas = <T extends SearchResponseType>(
  index: SearchIndices,
  actions: IListsActions<T>,
  selectors: IListsSelectors<T>,
  exportConfigurations?: Record<string, IExportConfiguration<T>>
) => {
  const { dataActions, exportActions, facetActions, uiActions } = actions
  const { dataSelectors, uiSelectors } = selectors
  const getRequestParams = createGetRequestParams(uiSelectors)
  const search = partial(searchSaga, [index])
  const loadMore = createLoadMore(
    getRequestParams,
    search,
    dataSelectors,
    dataActions
  )
  const chunkResults = createChunkResults(
    getRequestParams,
    search,
    uiSelectors,
    dataActions
  )
  const exportResults = createExport(
    getRequestParams,
    search,
    exportActions,
    uiSelectors,
    exportConfigurations
  )

  const getFacets = createFacet(search, uiSelectors, facetActions)

  const sagas = [
    () =>
      takeLatest(
        [
          uiActions.updateSearchText,
          uiActions.sort,
          uiActions.setFilter,
          uiActions.removeFilters,
          uiActions.setFilters,
          uiActions.update
        ],
        function* (action: { type: string }) {
          console.debug(`requesting search data ${action.type}`)
          yield put(dataActions.request())
        }
      ),
    () =>
      takeLatest(
        uiActions.moveColumn,
        function* (action: ReturnType<typeof uiActions.moveColumn>) {
          const columnState = yield* select(uiSelectors.getColumnState)

          const { id, position } = action.payload
          const indexOfColumn = columnState.findIndex((x) => x.columnId === id)

          if (indexOfColumn < 0) {
            return
          }

          const newColumnState = [...columnState]

          const column = newColumnState.splice(indexOfColumn, 1)
          newColumnState.splice(
            position,
            0,
            ...column.map((x) => ({ ...x, selected: true }))
          )
          yield put(uiActions.updateColumnState(newColumnState))
        }
      ),
    function* () {
      while (true) {
        // TODO, this almost works except the first time the columns change
        //       the previous column state is empty, causing an unnecessary
        //       refresh
        const columnState = yield* select(uiSelectors.getColumnState)
        yield take([uiActions.updateColumnState, uiActions.update])
        const newColumnState = yield* select(uiSelectors.getColumnState)
        const selectedColumns = columnState
          .filter((x) => x.selected)
          .map((x) => x.columnId)
          .sort()
        const newSelectedColumns = newColumnState
          .filter((x) => x.selected)
          .map((x) => x.columnId)
          .sort()

        const diff = difference(newSelectedColumns, selectedColumns)

        if (diff.length) {
          console.debug('column update request', diff)
          yield put(dataActions.request())
        }
      }
    },
    () => takeLatest(dataActions.request, chunkResults),
    () => takeLatest(dataActions.loadMore, loadMore),
    () => takeLatest(facetActions.request, getFacets),
    () =>
      takeLatest(
        [
          uiActions.setFilters,
          uiActions.removeFilters,
          uiActions.setFilter,
          uiActions.updateSearchText
        ],
        function* () {
          yield put(facetActions.reset())
        }
      ),
    () => takeLatest(exportActions.request, exportResults)
    // () =>
    //   takeLatest(
    //     exportActions.error,
    //     function* (action: ReturnType<typeof exportActions.error>) {
    //       yield* call(pushNotification, {
    //         message: `Failed to export: ${action?.payload?.message || ''}`,
    //         type: MessageBarType.error
    //       })

    //       yield* call(trackException, action.payload)
    //     }
    //   )
  ]

  return sagas
}
