import { flow } from 'lodash/fp'
import { createSelector } from 'reselect'
import { makeArrayFromString } from 'shared'
import { AppState } from '..'
import { RdotUserRoleEnum } from './rdotUser'

// RDOT
export const getUserState = (state: AppState) => state.user
export const getRdotUserState = flow(getUserState, (x) => x.rdot)
export const getRdotUserLoginError = flow(getRdotUserState, (x) => x.error)
export const getRdotUserOid = flow(getRdotUserState, (x) => x.item?.oid)
export const getRdotUsername = flow(getRdotUserState, (x) => x.item?.username)
export const getRdotName = flow(getRdotUserState, (x) => x.item?.name)
export const getRdotWsid = flow(getRdotUserState, (x) => x.item?.wsid)
export const getRdotUserPrimaryRepCode = flow(
  getRdotUserState,
  (x) => x.item?.primaryId
)
export const getRdotUserPoolIds = flow(getRdotUserState, (x) => x.item?.poolIds)
export const getRdotUserDisplayName = flow(
  getRdotUserState,
  (x) => x.item?.name
)
export const getRdotUserRoles = flow(getRdotUserState, (x) => x.item?.roles)
export const getRdotUserRepVisibility = createSelector(
  [getRdotUserPoolIds, getRdotUserPrimaryRepCode],
  (pools, primary) => {
    if (pools === undefined && primary === undefined) {
      return
    }

    return [...makeArrayFromString(primary || ''), ...(pools || [])].filter(
      Boolean
    )
  }
)
export const getIsHomeOfficeUser = createSelector([getRdotUserRoles], (roles) =>
  roles?.some((role) => role === RdotUserRoleEnum.Advisory_HomeOfficeUser)
)

export const getIsClientOnboardingAdmin = flow(getRdotUserRoles, (roles) =>
  roles?.some((role) => role === RdotUserRoleEnum.ClientOnboarding_Admin)
)

export const getIsInRoleAdvisoryOlt = flow(getRdotUserRoles, (roles) =>
  roles?.some((role) => role === RdotUserRoleEnum.Advisory_OLT)
)
export const getIsLoggedInUserJuniorAdvisor = createSelector(
  [getRdotUserRoles],
  (roles) => roles?.includes(RdotUserRoleEnum.Advisory_JuniorAdvisor)
)

// PREFERENCES
export const getPreferenceUserState = (state: AppState) => state.user.preference
export const getPreferenceUserPreferences = flow(
  getPreferenceUserState,
  (x) => x.items
)

export const getEnablePreviewPreference = flow(
  getPreferenceUserPreferences,
  (x) => x?.enablePreviewFeatures || false
)
export const getEnableDataMaskingPreference = flow(
  getPreferenceUserPreferences,
  (x) => x?.enableDataMasking || false
)
