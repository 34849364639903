import { IColumnDefinition } from 'features/Lists/core/contracts/IColumnDefinition'

enum ColumnIdsEnum {
  Id = 'Id',
  Account = 'Account',
  Security = 'Security',
  Ownership = 'Ownership',
  Cusip = 'Cusip',
  SecurityId = 'SecurityId',
  HeaderDate = 'HeaderDate'
}

export const SleeveColumnIds = { ...ColumnIdsEnum }
export type SleeveColumnIds = typeof SleeveColumnIds

export const SleeveColumnDefinitions: Record<string, IColumnDefinition> = {
  [SleeveColumnIds.Id]: {
    id: SleeveColumnIds.Id,
    name: 'Id',
    type: 'string',
    width: 200,
    filterable: true,
    sortable: true,
    searchable: true,
    facetable: true,
    dataPath: 'Id'
  },
  [SleeveColumnIds.Account]: {
    id: SleeveColumnIds.Account,
    name: 'Account Number',
    type: 'string',
    width: 250,
    filterable: true,
    sortable: true,
    searchable: true,
    facetable: true,
    dataPath: 'Account'
  },
  [SleeveColumnIds.Security]: {
    id: SleeveColumnIds.Security,
    name: 'Security',
    type: 'string',
    width: 250,
    filterable: true,
    sortable: true,
    searchable: true,
    facetable: true,
    dataPath: 'Security'
  },
  [SleeveColumnIds.Ownership]: {
    id: SleeveColumnIds.Ownership,
    name: 'Ownership',
    type: 'string',
    width: 250,
    filterable: true,
    sortable: true,
    searchable: true,
    facetable: false,
    dataPath: 'Ownership'
  },
  [SleeveColumnIds.Cusip]: {
    id: SleeveColumnIds.Cusip,
    name: 'CUSIP',
    type: 'string',
    width: 250,
    filterable: true,
    sortable: true,
    searchable: true,
    facetable: true,
    dataPath: 'Cusip'
  },
  [SleeveColumnIds.SecurityId]: {
    id: SleeveColumnIds.SecurityId,
    name: 'Sleeve Security Id',
    type: 'string',
    width: 250,
    filterable: true,
    sortable: true,
    searchable: false,
    facetable: false,
    dataPath: 'SecurityId'
  },
  [SleeveColumnIds.HeaderDate]: {
    id: SleeveColumnIds.HeaderDate,
    name: 'Header Date',
    type: 'date',
    width: 250,
    filterable: true,
    sortable: true,
    searchable: false,
    facetable: false,
    dataPath: 'HeaderDate'
  }
}
