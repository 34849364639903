import { IUMASleeve } from 'api/umasleeve.type'
import { createListsStore } from 'features/Lists/core/store'
import {
  createDefaultColumnState,
  mapColumnsToFilterDefinitions
} from 'features/Lists/shared/lists'
import { partial } from 'lodash/fp'
import { AppState } from 'store/shared/contracts'
import { SleeveColumnDefinitions } from '../ColumnDefinitions'

export const constructInitialSleeveColumnState = partial(
  createDefaultColumnState,
  [SleeveColumnDefinitions]
)

export const SleeveListStore = createListsStore<IUMASleeve>(
  'umasleeves',
  '@features/@lists/@umasleeves',
  {
    columnDefinitions: SleeveColumnDefinitions,
    filterDefinitions: mapColumnsToFilterDefinitions(SleeveColumnDefinitions)
  },
  (state: AppState) => state.features.lists.umasleeves
)

export const { reducer, sagas, actions, selectors } = SleeveListStore
