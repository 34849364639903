export const b64toBlob = (b64Data: string, sliceSize = 512) => {
  const dataParts = b64Data.split(',')
  const contentType = dataParts?.[0]
    ?.replace('data:', '')
    ?.replace(';base64', '')
  const byteCharacters: string = atob(dataParts?.[1])
  const byteArrays = []

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize)

    const byteNumbers = new Array(slice.length)
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }

    const byteArray = new Uint8Array(byteNumbers)
    byteArrays.push(byteArray)
  }

  const blob = new Blob(byteArrays, { type: contentType })
  return blob
}
