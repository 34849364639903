import { combineReducers } from 'redux'
import { delay, put, select, takeLatest } from 'typed-redux-saga'
import { ActionType, createAction, createReducer } from 'typesafe-actions'
import { rdotUserActions } from './rdotUser'
import { getPreferenceUserPreferences } from './selectors'

const PREFERENCES_REQUESTED = '@user/@preferences/PREFERENCES_REQUESTED'
const PREFERENCES_SUCCESS = '@user/@preferences/PREFERENCES_SUCCESS'
const PREFERENCES_FAILURE = '@user/@preferences/PREFERENCES_FAILURE'

export const preferenceUserFetchActions = {
  request: createAction(PREFERENCES_REQUESTED)(),
  success: createAction(PREFERENCES_SUCCESS)<IUserPreferences>(),
  failure: createAction(PREFERENCES_FAILURE)<Error>()
}

const UPDATE_PREFERNCES_REQUESTED =
  '@user/@preferences/UPDATE_PREFERNCES_REQUESTED'
const UPDATE_PREFERNCES_SUCCESS = '@user/@preferences/UPDATE_PREFERNCES_SUCCESS'
const UPDATE_PREFERNCES_FAILURE = '@user/@preferences/UPDATE_PREFERNCES_FAILURE'

export const preferenceUserUpdateActions = {
  request: createAction(UPDATE_PREFERNCES_REQUESTED)<IUserPreferences>(),
  success: createAction(UPDATE_PREFERNCES_SUCCESS)(),
  failure: createAction(UPDATE_PREFERNCES_FAILURE)<Error>()
}

const SET_PREFERNCES = '@user/@preferences/SET_PREFERNCES'
const RESET_PREFERNCES = '@user/@preferences/INIT_PREFERENCES'
export const preferenceUserActions = {
  set: createAction(SET_PREFERNCES)<{ key: string; value: unknown }>(),
  init: createAction(RESET_PREFERNCES)<IUserPreferences>()
}

export type PreferenceUserActionTypes =
  | ActionType<typeof preferenceUserFetchActions>
  | ActionType<typeof preferenceUserUpdateActions>
  | ActionType<typeof preferenceUserActions>

export interface IUserPreferences {
  [key: string]: unknown
  navigateInNewWindow?: boolean
  enablePreviewFeatures?: boolean
  enableDataMasking?: boolean
}

export interface IPreferenceUserFetchState {
  loading: boolean
  error?: Error
  preferences?: IUserPreferences
}

const initialPreferenceUserFetchState: IPreferenceUserFetchState = {
  loading: false
}

const preferenceUserFetchReducer = createReducer<
  IPreferenceUserFetchState,
  PreferenceUserActionTypes
>(initialPreferenceUserFetchState)
  .handleAction(preferenceUserFetchActions.success, (state, action) => ({
    ...state,
    loading: false,
    preferences: action.payload
  }))
  .handleAction(preferenceUserFetchActions.failure, (state, action) => ({
    ...state,
    error: action.payload
  }))

export interface IPreferenceUserUpdateState {
  loading: boolean
  error?: Error
}

const initialPreferenceUserUpdateState: IPreferenceUserUpdateState = {
  loading: false
}

const preferenceUserUpdateReducer = createReducer<
  IPreferenceUserUpdateState,
  PreferenceUserActionTypes
>(initialPreferenceUserUpdateState).handleAction(
  preferenceUserUpdateActions.failure,
  (state, action) => ({
    ...state,
    error: action.payload
  })
)

const preferenceReducer = createReducer<
  IUserPreferences | null,
  PreferenceUserActionTypes
>(null)
  .handleAction(
    preferenceUserActions.set,
    (state, { payload: { key, value } }) => ({
      ...state,
      [key]: value
    })
  )
  .handleAction(preferenceUserActions.init, (state, action) => action.payload)

export const preferenceUserReducer = combineReducers({
  fetch: preferenceUserFetchReducer,
  items: preferenceReducer,
  update: preferenceUserUpdateReducer
})

const preferencesKey = 'spa-user-preferences'

const fetchPreferences = function* () {
  const storagePreferences = JSON.parse(
    localStorage.getItem(preferencesKey) || '{}'
  )

  try {
    yield put(
      preferenceUserFetchActions.success({
        ...storagePreferences
      })
    )
  } catch (e: any) {
    yield put(preferenceUserFetchActions.failure(e))
    console.error('failed to load settings', e)
  }
}

const updatePreferences = function* (
  action: ReturnType<typeof preferenceUserUpdateActions.request>
) {
  try {
    localStorage.setItem(preferencesKey, JSON.stringify(action.payload))
    yield put(preferenceUserUpdateActions.success())
  } catch (e: any) {
    yield put(preferenceUserUpdateActions.failure(e))
  }
}

export const preferenceUserSagas = [
  () => takeLatest(preferenceUserFetchActions.request, fetchPreferences),
  () => takeLatest(preferenceUserUpdateActions.request, updatePreferences),
  () =>
    takeLatest(preferenceUserFetchActions.failure, function* () {
      const storagePreferences = JSON.parse(
        localStorage.getItem(preferencesKey) || '{}'
      )

      yield put(preferenceUserActions.init(storagePreferences))
    }),
  () =>
    takeLatest(
      preferenceUserFetchActions.success,
      function* (
        action: ReturnType<typeof preferenceUserFetchActions.success>
      ) {
        yield put(preferenceUserActions.init(action.payload))
      }
    ),
  () =>
    takeLatest(
      preferenceUserActions.set,
      function* (action: ReturnType<typeof preferenceUserActions.set>) {
        yield delay(500)
        const { key, value } = action.payload
        const preferences = yield* select(getPreferenceUserPreferences)
        const newPreferences = {
          ...(preferences || {}),
          [key]: value
        }
        yield put(preferenceUserUpdateActions.request(newPreferences))
      }
    ),
  () =>
    takeLatest(rdotUserActions.loginSuccess, function* () {
      yield put(preferenceUserFetchActions.request())
    })
]
