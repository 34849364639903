import { goBack, push, replace } from 'connected-react-router'
import { keyBy } from 'lodash'
import {
  // IEnvironmentApiConfiguration,
  IEnvironmentAppConfigurations
} from 'shared/services/environment/IEnvironmentConfiguration'
import { history } from 'shared/services/history'
import { saga_getApplicationsForUser } from 'store/user/graphUser'
import {
  call,
  delay,
  put,
  select,
  takeEvery,
  takeLatest
} from 'typed-redux-saga'
// import { billDotcomSsoLog } from '../../modules/Payments/api'
// import { IApiOptions } from '../../shared/contracts/IApiOptions'
import { trackException } from '../../shared/services/telemetry'
import {
  getApplicationSSOUrl,
  launchClientPortal,
  launchTeams,
  navigate
  // tryAcquireAccessToken
} from '../shared/sagas'
import { getEnvironmentConfigApps } from '../system'
import { preferenceUserActions } from '../user/preferenceUser'
import { navigationActions, uiActions } from './actions'
import { getThemePreference, themePreferenceKey } from './selectors'
import { ThemeType } from './types'

function* setInitialStateFromPreferences(
  action: ReturnType<typeof preferenceUserActions.init>
) {
  const themeType = action.payload?.[themePreferenceKey] as ThemeType

  if (themeType) {
    yield put(uiActions.updateTheme(themeType))
  }
}

export const uiSagas = [
  () =>
    takeLatest(
      uiActions.updateTheme,
      function* (action: ReturnType<typeof uiActions.updateTheme>) {
        const themePreference = yield* select(getThemePreference)
        if (themePreference !== action.payload) {
          yield put(
            preferenceUserActions.set({
              key: themePreferenceKey,
              value: action.payload
            })
          )
        }
      }
    ),
  () => takeLatest(preferenceUserActions.init, setInitialStateFromPreferences),
  () =>
    takeEvery(
      navigationActions.launchClientOnline,
      function* (
        action: ReturnType<typeof navigationActions.launchClientOnline>
      ) {
        const { wealthscapeId, partyId, path, additionalParams } =
          action.payload
        yield call(
          launchClientPortal,
          wealthscapeId,
          partyId,
          path,
          additionalParams
        )
      }
    ),
  () =>
    takeEvery(
      navigationActions.launchTeams,
      function* (action: ReturnType<typeof navigationActions.launchTeams>) {
        yield call(launchTeams, action.payload)
      }
    ),
  () =>
    takeEvery(
      navigationActions.launchApplication,
      function* (
        action: ReturnType<typeof navigationActions.launchApplication>
      ) {
        const url = yield* call(getApplicationSSOUrl, action.payload)

        yield call(navigate, url)
      }
    ),
  () =>
    takeEvery(
      navigationActions.launchWealthscape,
      function* (
        action: ReturnType<typeof navigationActions.launchWealthscape>
      ) {
        const configApps: IEnvironmentAppConfigurations = yield select(
          getEnvironmentConfigApps
        )
        const relayState = `${configApps['wealthscape']?.rootUrl}/ssc/singlecontainer/bd/generic_bportal/app/${action.payload}`
        yield put(
          navigationActions.launchConfigApplication({
            id: 'wealthscape',
            relayState
          })
        )
      }
    ),
  () =>
    takeEvery(
      navigationActions.launchSimon,
      function* (action: ReturnType<typeof navigationActions.launchSimon>) {
        const configApps: IEnvironmentAppConfigurations = yield select(
          getEnvironmentConfigApps
        )
        const relayState = `${configApps['simon']?.rootUrl}/simon/#/investment-details/${action.payload}/performance-analysis`
        yield put(
          navigationActions.launchConfigApplication({
            id: 'simon',
            relayState
          })
        )
      }
    ),
  () =>
    takeEvery(
      navigationActions.launchConfigApplication,
      function* (
        action: ReturnType<typeof navigationActions.launchConfigApplication>
      ) {
        const configApps: IEnvironmentAppConfigurations = yield select(
          getEnvironmentConfigApps
        )

        const userApps = yield* call(saga_getApplicationsForUser)

        const userAppLookup = keyBy(userApps, (x) => x.appId || '')

        const config = configApps[action.payload.id]

        if (config?.url) {
          yield call(navigate, config?.url)
          return
        }

        try {
          if (!config?.appId) {
            throw new Error(`No appId configured for ${action.payload.id}`)
          }

          const app = userAppLookup[config?.appId]
          const url = yield* call(getApplicationSSOUrl, app)

          if (!url) {
            throw new Error(`Unable to resolve url for ${action.payload.id}`)
          }

          const windowName = `spa_window_${new Date().getTime()}`

          if (!action.payload.relayState) {
            yield call(navigate, url, windowName)
          }

          if (action.payload.relayState) {
            yield call(
              navigate,
              `${url}?RelayState=${encodeURIComponent(
                action.payload.relayState
              )}`,
              windowName
            )
          }
          if (
            config &&
            config.rootUrl &&
            action.payload.postLoginRedirectPath
          ) {
            const appUrl = config.rootUrl + action.payload.postLoginRedirectPath
            yield delay(6000)
            yield call(navigate, appUrl, windowName)
          }
        } catch (e: any) {
          trackException(e, {
            source: 'navigation-actions.launch-config-application'
          })
          alert(
            'Failed to open the application, you may need to request access'
          )
        }
      }
    ),
  () =>
    takeEvery(
      navigationActions.launchBillDotCom,
      function* (
        action: ReturnType<typeof navigationActions.launchBillDotCom>
      ) {
        try {
          // Database logging for all bill.com users
          // const rockefellerApiConfig: IEnvironmentApiConfiguration =
          //   yield select(getRockefellerApiConfig)

          // const token: string = yield call(
          //   tryAcquireAccessToken,
          //   rockefellerApiConfig.scopes
          // )

          // const options: IApiOptions = {
          //   apiRoot: rockefellerApiConfig.root,
          //   accessToken: token
          // }

          // yield call(() => billDotcomSsoLog(options))

          const windowName = `spa_window_billdotcom`
          yield call(navigate, action.payload, windowName)
        } catch (e) {
          //
        }
      }
    ),
  () =>
    takeEvery(navigationActions.launchAccounting365, function* () {
      const windowName = `spa_window_accounting365`
      const apps: IEnvironmentAppConfigurations = yield select(
        getEnvironmentConfigApps
      )
      const url = apps.accounting365?.url
      yield call(navigate, url, windowName)
    }),
  () =>
    takeEvery(
      navigationActions.launchAccountingSubItem,
      function* (
        action: ReturnType<typeof navigationActions.launchAccountingSubItem>
      ) {
        const windowName = `spa_window_accounting365`
        yield call(navigate, action.payload, windowName)
      }
    ),
  () =>
    takeEvery(navigationActions.launchPaymentsReporting, function* () {
      const windowName = `spa_window_accounting365`
      const apps: IEnvironmentAppConfigurations = yield select(
        getEnvironmentConfigApps
      )
      const url = apps.paymentsreporting?.url
      yield call(navigate, url, windowName)
    }),
  () =>
    takeEvery(navigationActions.launchLexisNexis, function* () {
      const windowName = `spa_window_lexisnexis`
      const apps: IEnvironmentAppConfigurations = yield select(
        getEnvironmentConfigApps
      )
      const url = apps.lexisnexis?.url
      yield call(navigate, url, windowName)
    }),
  () =>
    takeEvery(navigationActions.launchRetirementReport, function* () {
      const windowName = `spa_window_retirementreport`
      const apps: IEnvironmentAppConfigurations = yield select(
        getEnvironmentConfigApps
      )
      const url = apps?.retirementReport?.url
      yield call(navigate, url, windowName)
    }),
  () =>
    takeEvery(navigationActions.launchProductReport, function* () {
      const windowName = `spa_window_productreport`
      const apps: IEnvironmentAppConfigurations = yield select(
        getEnvironmentConfigApps
      )
      const url = apps?.productReport?.url
      yield call(navigate, url, windowName)
    }),
  () =>
    takeEvery(navigationActions.launchSharePointChecks, function* () {
      const windowName = `spa_window_sharepointcheck`
      const apps: IEnvironmentAppConfigurations = yield select(
        getEnvironmentConfigApps
      )
      const url = apps?.sharepointcheck?.url
      yield call(navigate, url, windowName)
    }),
  () =>
    takeEvery(
      navigationActions.launchUrl,
      function* (action: ReturnType<typeof navigationActions.launchUrl>) {
        const { url, target } = action.payload
        yield call(navigate, url, target)
      }
    ),
  () =>
    takeEvery(navigationActions.launchRetirementTrueUpReport, function* () {
      const windowName = `spa_window_retirement_trueup_report`
      const apps: IEnvironmentAppConfigurations = yield select(
        getEnvironmentConfigApps
      )
      const url = apps?.retirementTrueUpReport?.url
      yield call(navigate, url, windowName)
    }),
  () =>
    takeEvery(navigationActions.launchRetirementAgingReport, function* () {
      const windowName = `spa_window_retirement_aging_report`
      const apps: IEnvironmentAppConfigurations = yield select(
        getEnvironmentConfigApps
      )
      const url = apps?.retirementAgingReport?.url
      yield call(navigate, url, windowName)
    }),
  () =>
    takeEvery(
      navigationActions.launchCompReport,
      function* (
        action: ReturnType<typeof navigationActions.launchCompReport>
      ) {
        const windowName = `spa_window_product_comp_report`
        const apps: IEnvironmentAppConfigurations = yield select(
          getEnvironmentConfigApps
        )

        if (action.payload === 'AI') {
          const url = apps?.altsCompReport?.url
          yield call(navigate, url, windowName)
        } else if (action.payload === 'RETIREMENT') {
          const url = apps?.retirementCompReport?.url
          yield call(navigate, url, windowName)
        }
      }
    ),
  () =>
    takeEvery(navigationActions.launchAgingReport, function* () {
      const windowName = `spa_window_product_aging_report`
      const apps: IEnvironmentAppConfigurations = yield select(
        getEnvironmentConfigApps
      )
      const url = apps?.altsAgingReport?.url
      yield call(navigate, url, windowName)
    }),
  () =>
    takeEvery(navigationActions.launchDeferredReport, function* () {
      const windowName = `spa_window_product_deferred_report`
      const apps: IEnvironmentAppConfigurations = yield select(
        getEnvironmentConfigApps
      )
      const url = apps?.altsDeferredReport?.url
      yield call(navigate, url, windowName)
    }),
  () =>
    takeEvery(push, function (action: ReturnType<typeof push>) {
      history.push(action.payload)
    }),
  () =>
    takeEvery(replace, function (action: ReturnType<typeof replace>) {
      history.replace(action.payload)
    }),
  () =>
    takeEvery(goBack, function () {
      history.back()
    })
]
