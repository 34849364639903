import { IUMATaxLot } from 'api/umataxlot.type'
import { createListsStore } from 'features/Lists/core/store'
import {
  createDefaultColumnState,
  mapColumnsToFilterDefinitions
} from 'features/Lists/shared/lists'
import { partial } from 'lodash/fp'
import { AppState } from 'store/shared/contracts'
import { TaxLotColumnDefinitions } from '../ColumnDefinitions'

export const constructInitialTaxLotsColumnState = partial(
  createDefaultColumnState,
  [TaxLotColumnDefinitions]
)

export const TaxLotListStore = createListsStore<IUMATaxLot>(
  'umataxlots',
  '@features/@lists/@umataxlots',
  {
    columnDefinitions: TaxLotColumnDefinitions,
    filterDefinitions: mapColumnsToFilterDefinitions(TaxLotColumnDefinitions)
  },
  (state: AppState) => state.features.lists.umataxlots
)

export const { reducer, sagas, actions, selectors } = TaxLotListStore
