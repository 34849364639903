import { combineReducers, Reducer } from 'redux'
import {
  reducer as umaTaxLotReducer,
  sagas as umaTaxLotSagas
} from '../../modules/Ram/features/uma/Dashboard/store'
import {
  reducer as umaSleeveReducer,
  sagas as umaSleeveSagas
} from '../../modules/Ram/features/uma/SleeveDashboard/store'
import {
  reducer as spaerrorReducer,
  sagas as spaerrorSagas
} from '../../modules/SPAAdvisor/features/Admin/store'

export const reducer: Reducer<any> = combineReducers({
  spaerror: spaerrorReducer,
  umataxlots: umaTaxLotReducer,
  umasleeves: umaSleeveReducer
})
export const sagas = [...spaerrorSagas, ...umaTaxLotSagas, ...umaSleeveSagas]
