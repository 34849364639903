import { lazy } from 'react'
import { combineReducers } from 'redux'
import { IModuleDefinition } from '../types'
import { errorsSummaryReducer } from './features/Admin/store/errorSummary'
import { dashboardReducer } from './features/DashBoard/store/reducers'
import { dashboardSagas } from './features/DashBoard/store/sagas'
import { advisorReducer } from './store/reducer'
import { SPAsagas } from './store/sagas'

const AdvisorContainer = lazy(() => import('./AdvisorContainer'))

const moduleDefinition: IModuleDefinition = {
  routeProps: {
    path: '/advisor/*',
    private: true,
    component: AdvisorContainer
  },
  name: 'Advisor'
}

export default moduleDefinition

export const advisorModuleReducer = combineReducers({
  advisor: advisorReducer,
  dashboard: dashboardReducer,
  SPAErrorsSummary: errorsSummaryReducer
})

export const advisorModuleSagas = [...dashboardSagas, ...SPAsagas]
