// import { ISecurityReference } from 'api/overrides.types'
import { getSecurityReferences } from 'api/security'
import axios from 'axios'
import { AppState } from 'store'
import {
  createAsyncReducer,
  createAsyncSelectors,
  getRockefellerApiOptions
} from 'store/shared'
import { call, cancelled, put, takeLatest } from 'typed-redux-saga'
import { createAsyncAction } from 'typesafe-actions'

export const securityReferenceFetchActions = createAsyncAction(
  '@modules/@security/@references/REQUEST',
  '@modules/@security/@references/SUCCESS',
  '@modules/@security/@references/FAILURE'
)<string, any, Error>()

export const securityReferenceFetchReducer = createAsyncReducer(
  securityReferenceFetchActions
)

const rootSelector = (state: AppState) => state.modules.securityReferenceFetch

export const {
  getError: getSecurityReferenceFetchError,
  getIsLoading: getSecurityReferenceFetchLoading,
  getResult: getSecurityReferenceFetchResult
} = createAsyncSelectors(rootSelector)

const onRequest = function* (
  action: ReturnType<typeof securityReferenceFetchActions.request>
) {
  // eslint-disable-next-line import/no-named-as-default-member
  const source = axios.CancelToken.source()
  const apiOptions = yield* call(getRockefellerApiOptions, source.token)

  try {
    const result = yield* call(
      getSecurityReferences,
      apiOptions,
      action.payload
    )
    yield put(securityReferenceFetchActions.success(result))
  } catch (e: any) {
    yield put(securityReferenceFetchActions.failure(e))
  } finally {
    if (yield* cancelled()) {
      source.cancel()
    }
  }
}

export const securityReferenceFetchSagas = [
  () => takeLatest(securityReferenceFetchActions.request, onRequest)
]
