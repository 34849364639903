import { ActionType, createAction } from 'typesafe-actions'
import {
  IGetRAMRequest,
  IRAMForm,
  IRAMFormRes,
  IRAMGetResponce,
  IRAMSummaryExportReq,
  IUpdateRAMStatusApiReq,
  IUpdateRAMStatusApiRes
} from '../api/types'

export const FETCH_EXISTING_RAM_DETAILS = `@modules/@RAM/FETCH_EXISTING_RAM_DETAILS`
export const FETCH_EXISTING_RAM_DETAILS_SUCCESS = `@modules/@RAM/FETCH_EXISTING_RAM_DETAILS_SUCCESS`
export const FETCH_EXISTING_RAM_DETAILS_FAILURE = `@modules/@RAM/FETCH_EXISTING_RAM_DETAILS_FAILURE`
export const FETCH_EXISTING_RAM_DETAILS_CLEAR = `@modules/@RAM/FETCH_EXISTING_RAM_DETAILS_CLEAR`

export const fetchExistingRAMActions = {
  request: createAction(FETCH_EXISTING_RAM_DETAILS)<IGetRAMRequest>(),
  success: createAction(FETCH_EXISTING_RAM_DETAILS_SUCCESS)<IRAMGetResponce>(),
  failure: createAction(FETCH_EXISTING_RAM_DETAILS_FAILURE)<Error>(),
  clear: createAction(FETCH_EXISTING_RAM_DETAILS_CLEAR)<undefined>()
}

export const EXPORT_RAM = `@modules/@RAM/EXPORT_RAM`
export const EXPORT_RAM_SUCCESS = `@modules/@RAM/EXPORT_RAM_SUCCESS`
export const EXPORT_RAM_FAILURE = `@modules/@RAM/EXPORT_RAM_FAILURE`

export const exportRAMToExcelActions = {
  request: createAction(EXPORT_RAM)<IRAMSummaryExportReq>(),
  success: createAction(EXPORT_RAM_SUCCESS)(),
  failure: createAction(EXPORT_RAM_FAILURE)<Error>()
}

export const INSERT_UPDATE_RAM = `@modules/@RAM/INSERT_UPDATE_RAM`
export const INSERT_UPDATE_RAM_SUCCESS = `@modules/@RAM/INSERT_UPDATE_RAM_SUCCESS`
export const INSERT_UPDATE_RAM_FAILURE = `@modules/@RAM/INSERT_UPDATE_RAM_FAILURE`
export const INSERT_UPDATE_RAM_CLEAR = `@modules/@RAM/INSERT_UPDATE_RAM_CLEAR`

export const insertUpdateRAMActions = {
  request: createAction(INSERT_UPDATE_RAM)<IRAMForm>(),
  success: createAction(INSERT_UPDATE_RAM_SUCCESS)<IRAMFormRes | undefined>(),
  failure: createAction(INSERT_UPDATE_RAM_FAILURE)<Error>(),
  clear: createAction(INSERT_UPDATE_RAM_CLEAR)()
}

export const UPDATE_RAM_REQUEST = `@modules/@RAM/UPDATE_RAM_REQUEST`
export const UPDATE_RAM_SUCCESS = `@modules/@RAM/UPDATE_RAM_SUCCESS`
export const UPDATE_RAM_FAILURE = `@modules/@RAM/UPDATE_RAM_FAILURE`
export const UPDATE_RAM_CLEAR = `@modules/@RAM/UPDATE_RAM_CLEAR`

export const updateRAMStatusActions = {
  request: createAction(UPDATE_RAM_REQUEST)<IUpdateRAMStatusApiReq>(),
  success: createAction(UPDATE_RAM_SUCCESS)<IUpdateRAMStatusApiRes>(),
  failure: createAction(UPDATE_RAM_FAILURE)<Error>(),
  clear: createAction(UPDATE_RAM_CLEAR)()
}

export type RAMFetchActionType = ActionType<typeof fetchExistingRAMActions>
export type exportRAMToExcelActionType = ActionType<
  typeof exportRAMToExcelActions
>
export type RAMInsertUpdateActionType = ActionType<
  typeof insertUpdateRAMActions
>
export type updateRAMStatusActionType = ActionType<
  typeof updateRAMStatusActions
>
export type RAMActionsTypes =
  | RAMFetchActionType
  | exportRAMToExcelActionType
  | RAMInsertUpdateActionType
  | updateRAMStatusActionType
