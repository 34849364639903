import { keyBy } from 'lodash'
import { flow } from 'lodash/fp'
import { createSelector } from 'reselect'
import { call, put, select, takeLatest } from 'typed-redux-saga'
import { ActionType, createAction, createReducer } from 'typesafe-actions'
import { AppState } from '..'
import { getRandomCapitalLetter, getRandomName } from '../../api/random'
import { notNullOrEmpty } from '../../shared'
import { isNotNullOrUndefined } from '../../shared/gaurds'
import { search } from '../shared/sagas'
import { rdotUserActions } from '../user/rdotUser'
import { getEnableDataMaskingPreference } from '../user/selectors'

export interface IDomainItem {
  id?: string
  ClientAdvisorID?: string
  ClientAdvisor?: string
  ClientAdvisorTeam?: string
  AdvisorKPI?: IAdvisorKPI
  BusinessSegment?: string
}

export interface IAdvisorKPI {
  poolMemberKPI?: IPoolMemberKPI[]
}

export interface IPoolMemberKPI {
  ClientAdvisorKPI?: IClientAdvisorKPI[]
  Custodian?: string
  CustodianNo?: string
  RCMEmail?: string
}

export interface IClientAdvisorKPI {
  RepNoPercentage?: string
  AlternateInvestment?: number
  AumBrokerage?: number
  AumManaged?: number
  AumTotal?: number
  LoanOutstanding?: number
  NetNewMoney?: number
  TOA?: number
  TradeBrokerageCount?: number
  TradeCommision?: number
  TradeManagedCount?: number
  cashAvlToTrade?: number
  cashInFlow?: number
  cashOutFlow?: number
}

const DOMAIN_REQUEST = '@context/@domain/DOMAIN_REQUEST'
const DOMAIN_SUCCESS = '@context/@domain/DOMAIN_SUCCESS'
const DOMAIN_ERROR = '@context/@domain/DOMAIN_ERROR'

export const domainContextRequestActions = {
  request: createAction(DOMAIN_REQUEST)(),
  success: createAction(DOMAIN_SUCCESS)<IDomainItem[]>(),
  failure: createAction(DOMAIN_ERROR)<Error>()
}

const SET_SELECTED_DOMAIN = '@context/@domain/SET_SELECTED_DOMAIN'
const SET_SELECTED_DOMAIN_REPS = '@context/@domain/SET_SELECTED_DOMAIN_REPS'
export const domainContextActions = {
  setSelectedDomain: createAction(SET_SELECTED_DOMAIN)<IDomainItem[]>(),
  setSelectedDomainReps: createAction(SET_SELECTED_DOMAIN_REPS)<string[]>()
}

export type DomainContextActionTypes =
  | ActionType<typeof domainContextRequestActions>
  | ActionType<typeof domainContextActions>

export interface IDomainContextState {
  items?: IDomainItem[]
  loading?: boolean
  error?: Error
  selected: IDomainItem[]
}

const initialState: IDomainContextState = {
  loading: true,
  selected: []
}

export const domainContextReducer = createReducer<
  IDomainContextState,
  DomainContextActionTypes
>(initialState)
  .handleAction(domainContextRequestActions.request, () => ({
    ...initialState,
    loading: true
  }))
  .handleAction(domainContextRequestActions.success, (state, action) => ({
    ...state,
    items: action.payload,
    loading: false
  }))
  .handleAction(domainContextRequestActions.failure, (state, action) => ({
    ...state,
    error: action.payload,
    loading: false
  }))
  .handleAction(domainContextActions.setSelectedDomain, (state, action) => ({
    ...state,
    selected: action.payload
  }))

export const getDomainContextState = (state: AppState) => state.context.domain
export const getIsDomainContextLoading = flow(
  getDomainContextState,
  (x) => x.loading
)
export const getDomainContextError = flow(getDomainContextState, (x) => x.error)
const getDomainContextItemsInternal = flow(
  getDomainContextState,
  (x) => x.items
)
export const getDomainContextItems = createSelector(
  [getDomainContextItemsInternal, getEnableDataMaskingPreference],
  (items, shouldMask) => {
    if (!shouldMask) {
      return items
    }
    const masked = items?.map((x) => {
      const advisorId = getRandomCapitalLetter() + x.ClientAdvisorID?.slice(-2)
      return {
        ...x,
        ClientAdvisor: getRandomName(),
        ClientAdvisorID: advisorId,
        ClientAdvisorTeam: getRandomName() + ' Team'
      }
    })

    return masked
  }
)
export const getSelectedDomainContextItems = flow(
  getDomainContextState,
  (x) => x.selected
)
export const getSelectedDomainContextRepCodes = createSelector(
  [getSelectedDomainContextItems],
  (items) => items.map((x) => x.id).filter(notNullOrEmpty) || []
)
export const getDomainContextRepCodes = createSelector(
  [getDomainContextItemsInternal],
  (items) => items?.map((x) => x.id).filter(notNullOrEmpty) || []
)

const fetchDomain = function* () {
  try {
    const result = yield* call(search, 'advisor' as const, {
      top: 1000,
      select: [
        'ClientAdvisorID',
        'ClientAdvisor',
        'id',
        'ClientAdvisorTeam',
        'AdvisorKPI/poolMemberKPI',
        'BusinessSegment',
        'RegionName',
        'HubName'
      ],
      orderBy: [
        {
          dataPath: 'total/noofaccounts',
          direction: 'desc' as const
        }
      ]
    })

    yield put(domainContextRequestActions.success(result.value))
  } catch (e: any) {
    yield put(domainContextRequestActions.failure(e))
  }
}

export const domainSagas = [
  () => takeLatest(domainContextRequestActions.request, fetchDomain),
  () =>
    takeLatest(
      domainContextActions.setSelectedDomainReps,
      function* (
        action: ReturnType<typeof domainContextActions.setSelectedDomainReps>
      ) {
        if (!action.payload?.length) {
          return
        }
        const domain: IDomainItem[] | undefined = yield select(
          getDomainContextItems
        )
        if (!domain?.length) {
          return
        }
        const lookup = keyBy(domain, (x) => x.id || '')
        const newDomain = action.payload
          .map((x) => lookup[x])
          .filter(isNotNullOrUndefined)

        if (!newDomain?.length) {
          return
        }
        yield put(domainContextActions.setSelectedDomain(newDomain))
      }
    ),
  () =>
    takeLatest(rdotUserActions.loginSuccess, function* () {
      yield put(domainContextRequestActions.request())
    })
]
