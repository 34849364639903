import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

export const HomeContainer: React.FC = () => {
  //const dispatch = useDispatch()
  const navigate = useNavigate()
  // const userRoles = useSelector(getRdotUserRoles)
  // const [firstRole] = userRoles || []
  useEffect(() => {
    navigate('/advisor', { replace: true })
    return
  }, [navigate])
  return <div />
}
