import { getEnumKeyByEnumValue } from 'modules/SPAAdvisor/features/common/Utility'
import { IApiOptions } from 'shared/contracts/IApiOptions'
import { getRockefellerApiOptions } from 'store/shared'
import { call, delay, put, takeLatest } from 'typed-redux-saga'
import {
  fetchExistingRAMService,
  insertUpdateRAMService,
  updateRAMStatusService
} from '../api/ramService'
import { IUpdateRAMStatusApiReq } from '../api/types'
import { RAMStatus } from '../features/common/Utility'
import {
  fetchExistingRAMActions,
  insertUpdateRAMActions,
  updateRAMStatusActions
} from './actions'

function* handleFetchExistingRAM(
  action: ReturnType<typeof fetchExistingRAMActions.request>
) {
  try {
    yield delay(300)
    const options: IApiOptions = yield call(getRockefellerApiOptions)
    const response = yield* call(() =>
      fetchExistingRAMService(action.payload, options)
    )
    if (response && response?.data) {
      yield put(fetchExistingRAMActions.success(response))
    } else {
      yield put(
        fetchExistingRAMActions.failure(
          new Error('unable to fetch dashboard list')
        )
      )
    }
  } catch (e: any) {
    yield put(fetchExistingRAMActions.failure(e))
  }
}

function* handleInsertUpdateRAM(
  action: ReturnType<typeof insertUpdateRAMActions.request>
) {
  try {
    const options = yield* call(getRockefellerApiOptions)
    const apiResp = yield* call(() =>
      insertUpdateRAMService(action.payload, options)
    )
    if (apiResp.errorCode === 0) {
      yield put(insertUpdateRAMActions.success(apiResp))
      try {
        if (action.payload.btnStatus === 'S') {
          const payload: IUpdateRAMStatusApiReq = {
            draftID: apiResp.outputResponse,
            requestStatus: getEnumKeyByEnumValue(RAMStatus, RAMStatus.PNDRVW)
          }
          const statusApiResp = yield* call(() =>
            updateRAMStatusService(payload, options)
          )
          if (statusApiResp.errorCode === 0) {
            yield put(
              updateRAMStatusActions.success({
                ...statusApiResp,
                msg: 'Successfully submitted the request'
              })
            )
          } else {
            yield put(
              updateRAMStatusActions.failure(
                new Error(
                  statusApiResp?.errormessage ||
                    'Unable to update Account Mapping Status, please try after sometime.'
                )
              )
            )
          }
        }
      } catch (e: any) {
        yield put(
          updateRAMStatusActions.failure(
            new Error(
              'Unable to update Account Mapping Status, please try after sometime.'
            )
          )
        )
      }
    } else {
      yield put(
        insertUpdateRAMActions.failure(
          new Error(
            apiResp?.errormessage ||
              'Unable to insert/update Account Mapping Form, please try after sometime.'
          )
        )
      )
    }
  } catch (e: any) {
    yield put(
      insertUpdateRAMActions.failure(
        new Error(
          e?.response?.data?.msg ||
            'Unable to insert/update Account Mapping Form, please try after sometime.'
        )
      )
    )
  }
}

function* handleUpdateRAMStatus(
  action: ReturnType<typeof updateRAMStatusActions.request>
) {
  try {
    const options = yield* call(getRockefellerApiOptions)
    const apiResp = yield* call(() =>
      updateRAMStatusService(action.payload, options)
    )
    if (apiResp.errorCode === 0) {
      const reqStatus =
        action.payload?.requestStatus &&
        Object.values(RAMStatus)[
          Object.keys(RAMStatus).indexOf(action.payload?.requestStatus?.trim())
        ]
      const msg =
        reqStatus === RAMStatus.PNDRVW
          ? 'Successfully submitted the request'
          : reqStatus === RAMStatus.REJECT
          ? 'Request Rejected!'
          : 'Request Approved Successfully!'
      const resp = { ...apiResp, msg: msg }
      yield put(updateRAMStatusActions.success(resp))
    } else {
      yield put(
        updateRAMStatusActions.failure(
          new Error(
            apiResp?.errormessage ||
              'Unable to update Account Mapping Status, please try after sometime.'
          )
        )
      )
    }
  } catch (e: any) {
    yield put(
      updateRAMStatusActions.failure(
        new Error(
          'Unable to update Accoount Mapping Status, please try after sometime.'
        )
      )
    )
  }
}

export const RAMsagas = [
  () => takeLatest(fetchExistingRAMActions.request, handleFetchExistingRAM),
  () => takeLatest(insertUpdateRAMActions.request, handleInsertUpdateRAM),
  () => takeLatest(updateRAMStatusActions.request, handleUpdateRAMStatus)
]
