import { Dictionary } from 'lodash'
import { IRAMForm } from 'modules/Ram/api/types'
import { IDataFilter } from 'shared/components/DataTableFilters/DataTableFilters'

//create a generic filter interface
export const NoData = 'No Data'

export enum RAMStatus {
  DRAFT = 'Draft',
  ACTIVE = 'Approved',
  PNDRVW = 'Pending Review',
  REJECT = 'Rejected',
  INACTV = 'Inactive',
  NULL = 'NULL',
  COMPLETE = 'Complete'
}

export const filterDashboardByFilterData = (
  ramList: IRAMForm[],
  filterBy: Dictionary<IDataFilter>
) => {
  let filteredData: IRAMForm[] = [...ramList]
  if (filterBy) {
    Object.entries(filterBy)?.map(([key, data]) => {
      filteredData = filteredData.filter((x) => {
        if (data?.value) {
          return (data.value as (string | number)[]).includes(
            (x[key as keyof IRAMForm] as string | number) || NoData
          )
        } else {
          return true
        }
      })
    })
  }
  return filteredData
}

export const filterDashboardBySearchtext = (
  items: IRAMForm[],
  searchText: string
) => {
  let filtered: IRAMForm[] = items || []
  if (items && searchText && Array.isArray(items)) {
    const lowerCaseText = searchText.toLowerCase()
    filtered = items.filter((p) => {
      if (
        p?.crD_ACCT_CD?.toLowerCase()?.includes(lowerCaseText) ||
        p?.perforM_ACCT_CD?.toLowerCase()?.includes(lowerCaseText) ||
        p?.portfolio?.toLowerCase()?.includes(lowerCaseText) ||
        p?.strategy?.toLowerCase()?.includes(lowerCaseText) ||
        p?.requestID?.toFixed(2)?.toString()?.startsWith(searchText) ||
        p?.custodiaN_CD?.toLowerCase()?.includes(lowerCaseText) ||
        p?.banK_ACCT_NUM?.toLowerCase()?.includes(lowerCaseText) ||
        p?.rcM_ID?.toLowerCase()?.includes(lowerCaseText) ||
        p?.businessSegment?.toString()?.startsWith(lowerCaseText) ||
        p?.clientAdvisor?.toString()?.startsWith(lowerCaseText) ||
        p?.clientAssociate?.toString()?.startsWith(lowerCaseText) ||
        p?.portfolioManager?.toString()?.startsWith(lowerCaseText) ||
        p?.legalEntityName?.toString()?.startsWith(lowerCaseText) ||
        p?.advisorRepCode?.toString()?.startsWith(lowerCaseText) ||
        p?.startDate?.toString()?.startsWith(lowerCaseText) ||
        p?.endDate?.toString()?.startsWith(lowerCaseText) ||
        p?.bloombergID?.toString()?.startsWith(lowerCaseText) ||
        p?.requestStatus?.toLowerCase()?.includes(lowerCaseText)
      ) {
        return true
      } else {
        return false
      }
    })
  }
  return filtered
}

export const filterExistingRAM = (
  data?: IRAMForm[],
  draftID?: number,
  requestID?: number
) => {
  if (data) {
    if (draftID && Number(draftID) > 0) {
      return data.find((x) => x.draftID === draftID)
    } else if (requestID && Number(requestID) > 0) {
      return data.find((x) => x.requestID === requestID)
    }
  }
}
