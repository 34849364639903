import { MessageBarType } from '@fluentui/react'
import { createReducer } from 'typesafe-actions'
import { IRAMGetResponce } from '../api/types'
import {
  RAMActionsTypes,
  exportRAMToExcelActions,
  fetchExistingRAMActions,
  insertUpdateRAMActions,
  updateRAMStatusActions
} from './actions'

export interface IRAMState {
  fetchExistingRAMState?: {
    inProgress: boolean
    error?: Error
    data?: IRAMGetResponce
  }
  insertUpdateRAMState?: {
    inProgress: boolean
    error?: Error
    msg?: string
    messageType?: MessageBarType
    outputResponse?: string
    data?: any
  }
  updateRAMState?: {
    inProgress: boolean
    error?: Error
    msg?: string
    messageType?: MessageBarType
    outputResponse?: string
    data?: any
  }
  exportRAMSummary?: {
    inProgress: boolean
    error?: Error
  }
}

const initialState: IRAMState = {}

export const ramReducer = createReducer<IRAMState, RAMActionsTypes>(
  initialState
)
  .handleAction(fetchExistingRAMActions.request, (state) => ({
    ...state,
    fetchExistingRAMState: {
      inProgress: true,
      error: undefined,
      data: undefined
    }
  }))
  .handleAction(fetchExistingRAMActions.success, (state, action) => ({
    ...state,
    fetchExistingRAMState: {
      inProgress: false,
      error: undefined,
      data: action.payload
    }
  }))
  .handleAction(fetchExistingRAMActions.failure, (state, action) => ({
    ...state,
    fetchExistingRAMState: {
      inProgress: false,
      error: action.payload,
      data: undefined
    }
  }))
  .handleAction(fetchExistingRAMActions.clear, (state) => ({
    ...state,
    fetchExistingRAMState: undefined
  }))
  .handleAction(insertUpdateRAMActions.request, (state) => ({
    ...state,
    insertUpdateRAMState: {
      inProgress: true,
      error: undefined,
      msg: undefined,
      messageType: undefined
    }
  }))
  .handleAction(insertUpdateRAMActions.success, (state, action) => ({
    ...state,
    insertUpdateRAMState: {
      inProgress: false,
      error: undefined,
      msg: 'Successfully saved record.',
      messageType: MessageBarType.success,
      data: action.payload,
      outputResponse: action.payload?.outputResponse
    }
  }))
  .handleAction(insertUpdateRAMActions.failure, (state, action) => ({
    ...state,
    insertUpdateRAMState: {
      inProgress: false,
      error: action.payload,
      msg: action.payload.message,
      messageType: MessageBarType.error
    }
  }))
  .handleAction(insertUpdateRAMActions.clear, (state) => ({
    ...state,
    insertUpdateRAMState: undefined
  }))
  .handleAction(updateRAMStatusActions.request, (state) => ({
    ...state,
    updateRAMState: {
      inProgress: true,
      error: undefined,
      msg: undefined,
      messageType: undefined
    }
  }))
  .handleAction(updateRAMStatusActions.success, (state, action) => ({
    ...state,
    updateRAMState: {
      inProgress: false,
      error: undefined,
      msg: action.payload.msg,
      messageType: MessageBarType.success,
      data: action.payload,
      outputResponse: action.payload?.outputResponse
    }
  }))
  .handleAction(updateRAMStatusActions.failure, (state, action) => ({
    ...state,
    updateRAMState: {
      inProgress: false,
      error: action.payload,
      msg: action.payload.message,
      messageType: MessageBarType.error
    }
  }))
  .handleAction(updateRAMStatusActions.clear, (state) => ({
    ...state,
    updateRAMState: undefined
  }))
  .handleAction(exportRAMToExcelActions.request, (state) => ({
    ...state,
    exportRAMSummary: {
      inProgress: true,
      error: undefined
    }
  }))
  .handleAction(exportRAMToExcelActions.success, (state) => ({
    ...state,
    exportRAMSummary: {
      inProgress: false,
      error: undefined
    }
  }))
  .handleAction(exportRAMToExcelActions.failure, (state, action) => ({
    ...state,
    exportRAMSummary: {
      inProgress: false,
      error: action.payload
    }
  }))
