import { ISPAError } from 'api/spaerror.types'
import { createListsStore } from 'features/Lists/core/store'
import {
  createDefaultColumnState,
  createFilter,
  mapColumnsToFilterDefinitions
} from 'features/Lists/shared/lists'
import { partial } from 'lodash/fp'
import { AppState } from 'store/shared/contracts'
import { SPAErrorColumnDefinitions } from '../ColumnDefinitions'

export const createSPAErrorListFilter = partial(createFilter, [
  SPAErrorColumnDefinitions
])

export const constructInitialSPAErrorsColumnState = partial(
  createDefaultColumnState,
  [SPAErrorColumnDefinitions]
)

export const spaErrorListStore = createListsStore<ISPAError>(
  'spaerror',
  '@features/@lists/@spaerror',
  {
    columnDefinitions: SPAErrorColumnDefinitions,
    filterDefinitions: mapColumnsToFilterDefinitions(SPAErrorColumnDefinitions)
  },
  (state: AppState) => state.features.lists.spaerror
)

export const { reducer, sagas, actions, selectors } = spaErrorListStore
