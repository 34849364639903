import { startOfMonth } from 'date-fns/fp'
import { groupBy, keyBy, sumBy, uniqBy } from 'lodash'
import { flow } from 'lodash/fp'
import { StrictEffect } from 'redux-saga/effects'
import { createSelector } from 'reselect'
import { all, call, delay, put, select, takeLatest } from 'typed-redux-saga'
import { ActionType, createAction, createReducer } from 'typesafe-actions'
import { AppState } from '..'
import { IAdvisorDetailResult, IPoolMemberKPI } from '../../api/advisor.types'
import {
  IAssetAllocationItem,
  IAUSHistoryWithRep,
  IProductAllocationItem,
  IRevenueHistoryItem,
  ISearchParams,
  ISearchResult,
  IWithDate,
  IWithRepId,
  SearchResponseType
} from '../../api/common.types'
import {
  OdataFilterOperatorEnum,
  OdataPropertyFilterGroup
} from '../../api/odata'
import { getRandomCapitalLetter, getRandomName } from '../../api/random'
import { stringArraysAreEqual } from '../../shared'
import { isNotNullOrUndefined } from '../../shared/gaurds'
import { search } from '../shared/sagas'
import {
  getEnableDataMaskingPreference,
  getIsHomeOfficeUser,
  getIsInRoleAdvisoryOlt,
  getIsLoggedInUserJuniorAdvisor,
  getRdotUsername,
  getRdotUserRoles
} from '../user/selectors'

const REQUEST = '@context/@advisor/REQUEST'
const START = '@context/@advisor/START'
const UPDATE = '@context/@advisor/UPDATE'
const ERROR = '@context/@advisor/ERROR'
const COMPLETE = '@context/@advisor/COMPLETE'

export const advisorContextUpdateActions = {
  request: createAction(REQUEST)<string[] | undefined>(),
  start: createAction(START)<string[]>(),
  success: createAction(UPDATE)<IAdvisorDetailResult[] | undefined>(),
  failure: createAction(ERROR)<Error>(),
  complete: createAction(COMPLETE)()
}

export type AdvisorContextActionTypes = ActionType<
  typeof advisorContextUpdateActions
>

export interface IAdvisorContextState {
  items?: IAdvisorDetailResult[]
  loading?: boolean
  error?: Error
}

const initialState: IAdvisorContextState = {
  loading: false
}

export const advisorContextReducer = createReducer<
  IAdvisorContextState,
  AdvisorContextActionTypes
>(initialState)
  .handleAction(advisorContextUpdateActions.start, () => ({
    ...initialState,
    loading: true
  }))
  .handleAction(advisorContextUpdateActions.success, (state, action) => ({
    ...state,
    items: action.payload
  }))
  .handleAction(advisorContextUpdateActions.failure, (state, action) => ({
    ...state,
    error: action.payload
  }))
  .handleAction(advisorContextUpdateActions.complete, (state) => ({
    ...state,
    loading: false
  }))

export const getAdvisorContextState = (state: AppState) => state.context.advisor
export const getAdvisorContextItems = flow(
  getAdvisorContextState,
  (x) => x.items
)
export const getAdvisorContextRepCodes = createSelector(
  [getAdvisorContextItems],
  (items) => items?.map((x) => x.id || '').filter(Boolean)
)
export const getIsAdvisorContextLoading = flow(
  getAdvisorContextState,
  (x) => x.loading
)
export const getAdvisorContextError = flow(
  getAdvisorContextState,
  (x) => x.error
)
export const getAdvisorContextRepLookup = createSelector(
  [getAdvisorContextItems],
  (items) => {
    if (!items) {
      return
    }

    const advisors =
      items
        ?.flatMap((x) => x.AdvisorKPI?.poolMemberKPI)
        .filter(isNotNullOrUndefined) || []

    const uniqAdvisors = uniqBy(advisors, (x) => x.CustodianNo)
    return keyBy(uniqAdvisors, (x) => x.CustodianNo) as Record<
      string,
      IPoolMemberKPI
    >
  }
)

export const getUserRepContributions = createSelector(
  [getAdvisorContextItems, getRdotUsername],
  (items, username) => {
    if (!username || !items) {
      return
    }

    const lowerCaseUsername = username?.toLowerCase()
    return items
      ?.flatMap(
        (advisor) =>
          advisor.AdvisorKPI?.poolMemberKPI &&
          advisor.AdvisorKPI.poolMemberKPI.map((kpi) => ({
            ...kpi,
            repId: advisor.id
          }))
      )
      .filter(isNotNullOrUndefined)
      .filter(
        ({ RCMEmail, ClientAdvisorKPI, repId }) =>
          RCMEmail?.toLowerCase() === lowerCaseUsername &&
          !!ClientAdvisorKPI?.[0]?.RepNoPercentage &&
          !!repId
      )
      .map(({ repId, ClientAdvisorKPI }) => ({
        repId,
        percentage: ClientAdvisorKPI?.[0]?.RepNoPercentage
          ? parseFloat(ClientAdvisorKPI[0].RepNoPercentage)
          : undefined
      }))
      .filter(({ percentage }) => percentage && !isNaN(percentage))
  }
)

export const getIsUserMemberOfAllContextReps = createSelector(
  [getRdotUsername, getIsHomeOfficeUser, getAdvisorContextItems],
  (username, userIsHomeOfficeUser, items) => {
    if (userIsHomeOfficeUser) {
      return true
    }

    if (!username || !items) {
      return false
    }

    const lowerCaseUsername = username.toLowerCase()

    return items.every(
      (item) =>
        item.AdvisorKPI?.poolMemberKPI
          ?.map((x) => x.RCMEmail?.toLowerCase())
          .some((x) => x === lowerCaseUsername) || false
    )
  }
)

export const getAdvisorContextProductAllocations = createSelector(
  [getAdvisorContextItems],
  (items) => {
    const productAllocations = items
      ?.flatMap((x) => x.AdvisorKPI?.productAllocation || [])
      .filter(isNotNullOrUndefined)

    return Object.entries(
      groupBy(productAllocations, ({ productType = '' }) => productType)
    )
      .map<IProductAllocationItem>(([key, value]) => ({
        productType: key,
        value: sumBy(value, (x) => x.value || 0)
      }))
      .sort((a, b) => ((a?.value || 0) > (b?.value || 0) ? -1 : 1))
  }
)

export const getAdvisorContextLvl1AssetAllocations = createSelector(
  [getAdvisorContextItems],
  (items) => {
    const assetAllocations = items
      ?.flatMap((x) => x.AdvisorKPI?.assetAllocationLvl1 || [])
      .filter(isNotNullOrUndefined)

    return Object.entries(
      groupBy(assetAllocations, ({ assetType = '' }) => assetType)
    )
      .map<IAssetAllocationItem>(([key, value]) => ({
        assetType: key,
        value: sumBy(value, (x) => x.value || 0)
      }))
      .sort((a, b) => ((a?.value || 0) > (b?.value || 0) ? -1 : 1))
  }
)

export const getAdvisorContextRevenueHistory = createSelector(
  [getAdvisorContextItems],
  (items) =>
    items
      ?.map(
        (x) =>
          x.revenueDetHistory?.map((rev) => ({ ...rev, repId: x.id })) || []
      )
      .flat<(IRevenueHistoryItem & IWithRepId)[][]>()
)

export interface IRevenueHistory extends IWithDate, IWithRepId {
  revenue?: number
  payout?: number
  advisorId?: string
  advisorName?: string
  username?: string
  category?: string
}

export const getAdvisorContextSortedRevenueHistory = createSelector(
  [getAdvisorContextRevenueHistory],
  (history) =>
    history
      ?.map((item): IRevenueHistoryItem & IWithRepId & IWithDate => {
        const date = flow(
          startOfMonth,
          (x) => new Date(x.getFullYear(), x.getMonth(), x.getDate())
        )(new Date(item.revYear || 0, item.revMonth ? item.revMonth - 1 : 0, 1))
        return {
          date,
          timestamp: date.getTime(),
          ...item
        }
      })
      .sort((a, b) => (a.timestamp || 0) - (b.timestamp || 0))
)

export const getMostRecentRevenueDate = createSelector(
  [getAdvisorContextSortedRevenueHistory],
  (sortedRevenue): Date | undefined => {
    if (!sortedRevenue) {
      return
    }

    const [lastItem] = sortedRevenue.slice(-1)
    return lastItem?.date
  }
)

export const getAdvisorContextVisibilityFilteredSortedRevenueHistory =
  createSelector(
    [
      getAdvisorContextSortedRevenueHistory,
      getEnableDataMaskingPreference,
      getUserRepContributions,
      getAdvisorContextRepLookup,
      getRdotUserRoles,
      getIsHomeOfficeUser,
      getRdotUsername,
      getIsInRoleAdvisoryOlt,
      getIsLoggedInUserJuniorAdvisor
    ],
    (
      revenue,
      shouldMask,
      userRepContributions,
      contextRepLookup,
      rdotUserRoles,
      isHomeOfficeUser,
      username,
      isOlt,
      isJuniorAdvisor
    ): IRevenueHistory[] | undefined => {
      if (
        revenue === undefined ||
        userRepContributions === undefined ||
        contextRepLookup === undefined ||
        rdotUserRoles === undefined ||
        isHomeOfficeUser === undefined ||
        username === undefined
      ) {
        return
      }

      const userRepContributionLookup = keyBy(
        userRepContributions,
        (x) => x.repId || ''
      )

      const history = revenue?.map((item): IRevenueHistory => {
        const advisorRepId = item.SplitRepcode || -1
        const advisorInfo = contextRepLookup[advisorRepId]
        const advisorEmail = advisorInfo?.RCMEmail?.toLowerCase()

        const shouldSeeAdvisorInfo =
          isOlt ||
          isHomeOfficeUser ||
          (isJuniorAdvisor && advisorEmail === username?.toLowerCase()) ||
          (!isJuniorAdvisor &&
            (userRepContributionLookup[item.repId || -1]?.percentage || 0) > 0)

        const advisor: IRevenueHistory = shouldSeeAdvisorInfo
          ? {
              advisorId: item.SplitRepcode,
              advisorName: advisorInfo?.Custodian || item.SplitRepcode,
              username: advisorInfo?.RCMEmail?.toLowerCase()
            }
          : {
              advisorId: 'OTH',
              advisorName: 'Other Advisors'
            }

        return {
          date: item.date,
          timestamp: item.timestamp,
          revenue: item.revenueDet?.compRevenue,
          payout: shouldSeeAdvisorInfo ? item.revenueDet?.payout : undefined,
          repId: item.repId,
          category: item.AssetType || 'Other',
          ...advisor
        }
      })

      if (shouldMask) {
        const allReps = groupBy(history, (x) => x?.advisorId || '')
        const repMap = Object.keys(allReps)
          .map((x) => ({
            maskedId: getRandomCapitalLetter() + (x || '').slice(-2),
            maskedName: getRandomName(),
            rep: x
          }))
          .reduce(
            (a, { rep, maskedId, maskedName }) => ({
              ...a,
              [rep]: { maskedId, maskedName }
            }),
            {} as any
          )

        history.forEach((x) => {
          const map = repMap[x?.advisorId || '']

          x.advisorId = map?.maskedId
          x.advisorName = map?.maskedName
        })
      }

      return history
    }
  )

export const getAdvisorContextAusHistory = createSelector(
  [getAdvisorContextItems],
  (items) =>
    items
      ? items
          .map((x) =>
            (x.AdvisorKPI?.ausHistory || []).map(
              (item): IAUSHistoryWithRep => ({
                ...item,
                repId: x.ClientAdvisorID
              })
            )
          )
          .flat()
      : undefined
)

export const getAdvisorContextTopTenPositions = createSelector(
  [getAdvisorContextItems],
  (items) => {
    const positions = items
      ?.flatMap((x) => x.AdvisorKPI?.top10Positions || [])
      .filter(isNotNullOrUndefined)
    const groups = groupBy(positions, (x) => x.SecurityId || x.Description)
    return Object.entries(groups)
      .map(([, value]) => ({
        ...value[0],
        Value: sumBy(value, (x) => x.Value || 0)
      }))
      .sort((a, b) => b.Value - a.Value)
      .slice(0, 10)
  }
)

const requestAdvisors = function* (
  action: ReturnType<typeof advisorContextUpdateActions.request>
) {
  yield delay(25)

  console.debug('advisor context update requested', action.payload)

  if (!action.payload || !action.payload.length) {
    yield put(advisorContextUpdateActions.success(undefined))
    yield put(advisorContextUpdateActions.complete())
    return
  }

  const currentReps: string[] | undefined = yield select(
    getAdvisorContextRepCodes
  )
  if (currentReps && stringArraysAreEqual(currentReps, action.payload)) {
    return
  }

  yield put(advisorContextUpdateActions.start(action.payload))
}

function* fetchAdvisors(
  action: ReturnType<typeof advisorContextUpdateActions.start>
) {
  const filter: OdataPropertyFilterGroup = {
    and: [
      {
        operator: OdataFilterOperatorEnum.searchin,
        path: 'ClientAdvisorID',
        type: 'string',
        value: action.payload
      }
    ]
  }

  const select = [
    'HubName',
    'BusinessSegment',
    'ClientAdvisorTeam',
    'RegionName',
    'PoolFlag',
    'ClientAdvisorID',
    'id',
    'revenueDetHistory',
    'total',
    'AdvisorKPI',
    'tradeActivity',
    'ClientAdvisor'
  ]

  const baseRequestParams: Partial<ISearchParams> = {
    filters: [filter],
    orderBy: [{ dataPath: 'id', direction: 'asc' }],
    select
  }

  const initialChunkSize = 10
  const chunkSize = 40

  try {
    const peek = yield* call(search, 'advisor' as const, {
      ...baseRequestParams,
      count: true,
      top: initialChunkSize
    })

    const chunks: ISearchResult<SearchResponseType>[] = [peek]

    const totalCount = peek['@odata.count'] || 0

    if (totalCount > initialChunkSize) {
      const iterations = Math.ceil((totalCount - initialChunkSize) / chunkSize)

      const actions = Array(iterations)
        .fill(1)
        .map((_, i) => i)
        .map(
          (
            i
          ): (() => Generator<
            StrictEffect,
            ISearchResult<SearchResponseType>,
            unknown
          >) =>
            function* () {
              const skip = i * chunkSize + initialChunkSize
              const top = chunkSize

              const chunk = yield* call(search, 'advisor' as const, {
                ...baseRequestParams,
                top,
                skip
              })

              return chunk
            }
        )

      const results = yield* all(actions.map((action) => call(action)))
      chunks.push(...results)
    }

    const results = chunks
      .map(({ value }) => value)
      .filter(isNotNullOrUndefined)
      .flat()

    yield put(
      advisorContextUpdateActions.success(results as IAdvisorDetailResult[])
    )
  } catch (e: any) {
    yield put(advisorContextUpdateActions.failure(e))
  }

  yield put(advisorContextUpdateActions.complete())
}

export const advisorSagas = [
  () => takeLatest(advisorContextUpdateActions.request, requestAdvisors),
  () => takeLatest(advisorContextUpdateActions.start, fetchAdvisors)
]
