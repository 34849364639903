import {
  Callout,
  Checkbox,
  ChoiceGroup,
  CommandButton,
  DatePicker,
  DefaultButton,
  DirectionalHint,
  Dropdown,
  IChoiceGroupOption,
  Icon,
  IDropdownOption,
  Label,
  PrimaryButton,
  Stack,
  Target,
  TextField
} from '@fluentui/react'
import { add, format, sub } from 'date-fns'
import { uniq } from 'lodash'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { isDefined } from '../../gaurds'
import { IColumnWithFilter } from './types'

//create a generic filter interface
export const NoData = 'No Data'

export const FilterTypes = {
  default: 'default',
  dateRange: 'dateRange',
  numberRange: 'numberRange',
  radioButtonList: 'radioButtonList'
}

export type RangeType = {
  minValue: number | string
  maxValue: number | string
  rangeKey?: string
}

export interface IDataFilter {
  value:
    | string
    | number
    | boolean
    | (string | number | boolean)[]
    | RangeType
    | undefined
    | null

  name?: string
  searchText?: string | undefined | null
}

export const NumberRangeOptions: IDropdownOption[] = [
  { key: '>', text: 'Greater than' },
  { key: '<', text: 'Less than' },
  { key: '=', text: 'Equals' },
  { key: 'customNumber', text: 'Range' }
]
export const DateRangeOptions: IChoiceGroupOption[] = [
  { key: 'last7', text: 'Last 7 days' },
  { key: 'last30', text: 'Last 30 days' },
  { key: 'last90', text: 'Last 90 days' },
  { key: 'future', text: 'Future days' },
  { key: 'today', text: 'Today' },
  { key: 'custom', text: 'Custom date range' }
]

export const radioButtonOptions: IChoiceGroupOption[] = [
  { key: 'true', text: 'Yes' },
  // { key: 'No Data', text: 'No' }
  { key: 'false', text: 'No' }
]

export interface Dictionary<T> {
  [Key: string]: T
}

interface IDataTableFilters<T> {
  data: T[]
  column: IColumnWithFilter
  filters?: Dictionary<IDataFilter>
  isBeakVisible?: boolean
  target?: Target
  onDismiss?: () => void
  onColumnSort: (column: IColumnWithFilter, sortOrder: boolean) => void
  onApply?: (key: string, value: IDataFilter) => void
  onCustomHeaderClick?: (
    header: string,
    key: string
  ) => { name: string; value: string[] } | undefined
}

const DataTableFilters = <T, K extends keyof T>({
  data,
  column,
  isBeakVisible,
  target,
  filters,
  onDismiss,
  onColumnSort,
  onApply,
  onCustomHeaderClick
}: IDataTableFilters<T>) => {
  const [filterValue, setFilterValue] = useState<IDataFilter>({
    value: undefined
  })

  type Value = string | number | boolean

  const isValueaBoolean = useMemo(
    () => (value: Value) => {
      if (typeof value === 'boolean') {
        if (value) {
          return 'Yes'
        } else {
          return 'No'
        }
      }
      return value
    },
    []
  )

  const multiSelectionData = useMemo(() => {
    if (column?.customMultiSelectList?.length) {
      return column?.customMultiSelectList
    } else if (
      !column.filterType ||
      column.filterType === FilterTypes.default
    ) {
      const filteredData = uniq(
        (
          data?.map((x) =>
            isValueaBoolean(
              x[column.key as K] as Extract<
                typeof x[K],
                string | number | boolean
              >
            )
          ) || NoData
        )?.filter(isDefined)
      ).sort()
      const searchText = filterValue?.searchText ?? ''
      return filteredData?.filter((el) =>
        el?.toString().toLowerCase().includes(searchText?.toLowerCase())
      )
    } else {
      return undefined
    }
  }, [
    column?.customMultiSelectList,
    column.filterType,
    column.key,
    data,
    filterValue?.searchText,
    isValueaBoolean
  ])

  const handleOnCallOutDismiss = useCallback(() => {
    if (onDismiss) {
      onDismiss()
    }
  }, [onDismiss])

  const handleCustomHeaderClick = useCallback(
    (header: string, key: string) => {
      if (onCustomHeaderClick) {
        const newValue = onCustomHeaderClick(header, key)
        if (newValue) {
          setFilterValue((prevState) => ({
            ...prevState,
            value: newValue?.value,
            name: newValue?.name
          }))
        }
      }
    },
    [onCustomHeaderClick, setFilterValue]
  )

  const handleApplyClick = useCallback(() => {
    if (onApply) {
      onApply(column.key, filterValue)
    }
    if (onDismiss) {
      onDismiss()
    }
  }, [column.key, filterValue, onApply, onDismiss])

  const handleRadioButtonSelection = useCallback(
    (value: string) => {
      setFilterValue({
        ...filterValue,
        value: value,
        name: column.name || column.key
      })
    },
    [column.key, column.name, filterValue]
  )

  const isValueBoolean = (value: string | number | boolean) => {
    if (value === 'Yes') {
      return 'Yes'
    } else if (value === 'No') {
      return 'No'
    }
    return value
  }

  const handleMultiSelectChange = useCallback(
    (checked: boolean | undefined, value: string | number | boolean) => {
      if (checked) {
        setFilterValue({
          ...filterValue,
          value: [
            ...((filterValue?.value || []) as string[] | number[] | boolean[]),
            isValueBoolean(value)
          ],
          name: column.name || column.key
        })
      } else {
        const shallowCopy = [
          ...((filterValue?.value || []) as string[] | number[] | boolean[])
        ]
        const index = shallowCopy.indexOf(value)
        if (index > -1) {
          shallowCopy.splice(index, 1)
          setFilterValue({
            ...filterValue,
            value: shallowCopy,
            name: column.name || column.key
          })
        }
      }
    },
    [column.key, column.name, filterValue]
  )

  const handleSelectAllOptions = useCallback(() => {
    setFilterValue({
      ...filterValue,
      value: multiSelectionData,
      name: column.name || column.key
    })
  }, [column.key, column.name, filterValue, multiSelectionData])

  const handleRemoveFilter = useCallback(() => {
    setFilterValue({
      ...filterValue,
      value: undefined,
      name: column.name || column.key,
      searchText: undefined
    })
  }, [column.key, column.name, filterValue])

  const onSearchTextChange = useCallback((value: string | undefined) => {
    setFilterValue((prevState) => ({
      ...prevState,
      searchText: value
    }))
  }, [])

  const onMinValueChange = useCallback(
    (ev: unknown, newValue: string | number, rangeKey?: string) => {
      setFilterValue((prevState) => ({
        ...prevState,
        value: {
          ...(prevState?.value as RangeType),
          minValue: newValue,
          rangeKey
        }
      }))
    },
    []
  )
  const onMaxValueChange = useCallback(
    (ev: unknown, newValue: string | number, rangeKey?: string) => {
      setFilterValue((prevState) => ({
        ...prevState,
        value: {
          ...(prevState?.value as RangeType),
          maxValue: newValue,
          rangeKey
        }
      }))
    },
    []
  )
  const formatDate = (
    date: Date | undefined | null,
    formatStr = 'MM/dd/yyyy'
  ) => (date ? format(date, formatStr) : '')

  const handleDateChange = useCallback(
    (
      ev?: React.FormEvent<HTMLElement | HTMLInputElement> | undefined,
      option?: IChoiceGroupOption | undefined
    ) => {
      const now = new Date()

      let from = ''
      let to = ''
      switch (option?.key) {
        case 'last7':
          from = formatDate(sub(now, { days: 7 }), 'yyyy/MM/dd')
          to = formatDate(now, 'yyyy/MM/dd')
          break
        case 'last30':
          from = formatDate(sub(now, { days: 30 }), 'yyyy/MM/dd')
          to = formatDate(now, 'yyyy/MM/dd')
          break
        case 'last90':
          from = formatDate(sub(now, { days: 90 }), 'yyyy/MM/dd')
          to = formatDate(now, 'yyyy/MM/dd')
          break
        case 'today':
          from = formatDate(now, 'yyyy/MM/dd')
          to = formatDate(now, 'yyyy/MM/dd')
          break
        case 'future':
          from = formatDate(add(now, { days: 1 }), 'yyyy/MM/dd')
          to = ''
          break
      }
      onMinValueChange(null, from, option?.key)
      onMaxValueChange(null, to, option?.key)
    },
    [onMinValueChange, onMaxValueChange]
  )

  const updateNumberRange = useCallback(
    (min: null | string, max: null | string, key: string) => {
      setFilterValue({
        ...filterValue,
        value: {
          minValue: min,
          maxValue: max,
          rangeKey: key
        } as RangeType
      })
    },
    [filterValue]
  )

  const onNumberOptionChange = useCallback(
    (
      ev: React.FormEvent<HTMLDivElement>,
      options: IDropdownOption | undefined
    ) => {
      updateNumberRange(
        (filterValue?.value as RangeType)?.minValue.toString() ?? null,
        (filterValue?.value as RangeType)?.maxValue.toString() ?? null,
        options?.key as string
      )
    },
    [filterValue, updateNumberRange]
  )
  useEffect(() => {
    setFilterValue({
      value: filters?.[column.key]?.value,
      name: column.name || column.key,
      searchText: filters?.[column.key]?.searchText
    })
  }, [column, filters])

  return (
    <Callout
      target={target}
      isBeakVisible={isBeakVisible || false}
      directionalHint={DirectionalHint.bottomCenter}
      directionalHintFixed={false}
      setInitialFocus
      onDismiss={() => handleOnCallOutDismiss()}
    >
      <div style={{ padding: 10 }}>
        {column?.allowSorting && (
          <Stack>
            <CommandButton
              iconProps={{ iconName: 'SortUp' }}
              text={`Sort Ascending`}
              onClick={() => onColumnSort(column, false)}
            />
            <CommandButton
              iconProps={{ iconName: 'SortDown' }}
              text={`Sort Descending`}
              onClick={() => onColumnSort(column, true)}
            />
          </Stack>
        )}

        {column?.allowFilter && (
          <>
            <hr
              style={{
                margin: 1,
                borderTop: '1px soild lightgray',
                opacity: 0.5
              }}
            />
            <Stack
              style={{
                margin: 2,
                alignItems: 'center',
                color: 'rgb(0, 120, 212)'
              }}
              horizontal={true}
              tokens={{ childrenGap: 10 }}
            >
              <Icon iconName="filter" style={{ paddingLeft: 6 }} />
              <Label style={{ color: 'rgb(0, 120, 212)' }}>Filter</Label>
            </Stack>
            <Stack
              horizontal={true}
              tokens={{ childrenGap: 5 }}
              horizontalAlign="end"
            >
              {multiSelectionData && (
                <CommandButton
                  iconProps={{ iconName: 'MultiSelectMirrored' }}
                  onClick={handleSelectAllOptions}
                >
                  Select All
                </CommandButton>
              )}
              <CommandButton
                iconProps={{ iconName: 'ClearFilter' }}
                onClick={handleRemoveFilter}
              >
                Remove Filter
              </CommandButton>
            </Stack>
            {!!column?.customHeaderList?.length && (
              <Stack
                horizontal={true}
                tokens={{ childrenGap: 5 }}
                horizontalAlign="end"
              >
                {column?.customHeaderList?.map((header, index) => (
                  <CommandButton
                    key={index}
                    iconProps={{ iconName: 'MultiSelectMirrored' }}
                    onClick={() => {
                      handleCustomHeaderClick(header, column?.key)
                    }}
                  >
                    {header}
                  </CommandButton>
                ))}
              </Stack>
            )}

            <div
              style={{
                maxHeight: 300,
                minWidth: 250,
                maxWidth: 300,
                overflowY: 'auto',
                padding: 3,
                wordBreak: 'break-all'
              }}
            >
              {column?.showSearch && (
                <div>
                  <TextField
                    placeholder="Search"
                    value={filterValue?.searchText || ''}
                    onChange={(ev, value) => {
                      onSearchTextChange(value)
                    }}
                  />
                </div>
              )}
              {multiSelectionData?.map((x, i) => (
                <div style={{ padding: 5 }} key={i}>
                  <Checkbox
                    label={x.toString()}
                    onChange={(ev, checked) =>
                      handleMultiSelectChange(checked, x)
                    }
                    checked={
                      (
                        filterValue?.value as (string | number | boolean)[]
                      )?.includes(x) || false
                    }
                  />
                </div>
              ))}

              {column?.filterType === FilterTypes.dateRange && (
                <>
                  <ChoiceGroup
                    options={column?.customDateOptions || DateRangeOptions}
                    value={(filterValue?.value as RangeType)?.rangeKey || ''}
                    selectedKey={
                      (filterValue?.value as RangeType)?.rangeKey || ''
                    }
                    onChange={handleDateChange}
                  />
                  <Stack
                    horizontal={true}
                    tokens={{ childrenGap: 10 }}
                    verticalAlign="center"
                  >
                    <DatePicker
                      styles={{ textField: { width: 125 } }}
                      placeholder="Start Date"
                      ariaLabel="Start Date"
                      disabled={
                        (filterValue?.value as RangeType)?.rangeKey !== 'custom'
                      }
                      value={
                        (filterValue?.value as RangeType)?.minValue &&
                        (filterValue?.value as RangeType)?.rangeKey === 'custom'
                          ? new Date(
                              (filterValue?.value as RangeType)?.minValue
                            )
                          : undefined
                      }
                      onSelectDate={(date) => {
                        onMinValueChange(
                          null,
                          formatDate(date, 'yyyy/MM/dd'),
                          'custom'
                        )
                      }}
                      formatDate={formatDate}
                    />
                    <b>to</b>
                    <DatePicker
                      styles={{ textField: { width: 125 } }}
                      placeholder="End Date"
                      ariaLabel="End Date"
                      disabled={
                        (filterValue?.value as RangeType)?.rangeKey !== 'custom'
                      }
                      value={
                        (filterValue?.value as RangeType)?.maxValue &&
                        (filterValue?.value as RangeType)?.rangeKey === 'custom'
                          ? new Date(
                              (filterValue?.value as RangeType)?.maxValue
                            )
                          : undefined
                      }
                      onSelectDate={(date) => {
                        onMaxValueChange(
                          null,
                          formatDate(date, 'yyyy/MM/dd'),
                          'custom'
                        )
                      }}
                      formatDate={formatDate}
                    />
                  </Stack>
                </>
              )}
              {column?.filterType === FilterTypes.numberRange && (
                <>
                  <Dropdown
                    options={NumberRangeOptions}
                    onChange={onNumberOptionChange}
                    selectedKey={
                      (filterValue?.value as RangeType)?.rangeKey || '='
                    }
                  />
                  {(filterValue?.value as RangeType)?.rangeKey ===
                    'customNumber' && (
                    <Stack
                      horizontal={true}
                      styles={{ root: { paddingTop: '10px' } }}
                      tokens={{ childrenGap: 5 }}
                    >
                      <TextField
                        placeholder={'From'}
                        type="number"
                        value={(
                          filterValue?.value as RangeType
                        )?.minValue?.toString()}
                        onChange={(ev, value) => {
                          const min = value || 0
                          const max = parseFloat(
                            (
                              filterValue?.value as RangeType
                            )?.maxValue?.toString()
                          )
                          updateNumberRange(
                            min.toString(),
                            max.toString(),
                            'customNumber'
                          )
                        }}
                      />
                      <TextField
                        placeholder={'To'}
                        type="number"
                        value={(
                          filterValue?.value as RangeType
                        )?.maxValue?.toString()}
                        onChange={(ev, value) => {
                          const max = value || 0
                          const min =
                            (
                              filterValue?.value as RangeType
                            )?.minValue?.toString() || '0'

                          updateNumberRange(
                            min,
                            max?.toString(),
                            'customNumber'
                          )
                        }}
                      />
                    </Stack>
                  )}
                  {(filterValue?.value as RangeType)?.rangeKey !==
                    'customNumber' && (
                    <Stack
                      horizontal={true}
                      styles={{ root: { paddingTop: '10px' } }}
                    >
                      <TextField
                        type="number"
                        styles={{ root: { width: '100%' } }}
                        value={
                          (
                            filterValue?.value as RangeType
                          )?.minValue?.toString() ?? 0
                        }
                        onChange={(ev, value) => {
                          const val = value || ''
                          updateNumberRange(
                            val.toString() || '',
                            val.toString(),
                            (filterValue?.value as RangeType)?.rangeKey || '='
                          )
                        }}
                      />
                    </Stack>
                  )}
                </>
              )}
              {!(column?.filterType !== FilterTypes.radioButtonList) && (
                <ChoiceGroup
                  options={column?.radioButtonOptions || radioButtonOptions}
                  // value={(filterValue?.value as string) || ''}
                  selectedKey={(filterValue?.value as string) || ''}
                  onChange={(ev, value) => {
                    handleRadioButtonSelection(value?.key || '')
                  }}
                />
              )}
            </div>
            <Stack
              horizontal={true}
              horizontalAlign="end"
              tokens={{ childrenGap: 5 }}
              style={{ marginTop: '10px' }}
            >
              <DefaultButton
                text="CANCEL"
                onClick={() => handleOnCallOutDismiss()}
              />
              <PrimaryButton text="APPLY" onClick={handleApplyClick} />
            </Stack>
          </>
        )}
      </div>
    </Callout>
  )
}

export default DataTableFilters
