import { flow } from 'lodash'
import { call, put, select, takeLatest } from 'typed-redux-saga'
import { ActionType, createAction, createReducer } from 'typesafe-actions'
import { IGraphApiSendMailRequest, sendMail } from '../../api/graph'
import { AppState } from '../../store'
import { getMicrosoftGraphApiOptions } from '../../store/shared/sagas'
import {
  getEnvironmentName,
  getIsEnvironmentProduction
} from '../../store/system'
import { getRdotUsername } from '../../store/user/selectors'
import { generateReportErrorEmail } from './ErrorEmail'

const REPORT_ERROR = '@features/@errorPage/REPORT_ERROR'
const REPORT_ERROR_SUCCESS =
  '@shared/@errorWindow/@reportErrorEmail/REPORT_ERROR_SUCCESS'
const REPORT_ERROR_FAILURE =
  '@shared/@errorWindow/@reportErrorEmail/REPORT_ERROR_FAILURE'

export const errorPageActions = {
  reportError: createAction(REPORT_ERROR)<{
    error?: Error
    errorInfo?: React.ErrorInfo
  }>(),
  reportErrorSuccess: createAction(REPORT_ERROR_SUCCESS)(),
  reportErrorFailure: createAction(REPORT_ERROR_FAILURE)<Error>()
}

export type ReportErrorEmailActionTypes = ActionType<typeof errorPageActions>

export interface IReportErrorEmailState {
  loading?: boolean
}

const initialState: IReportErrorEmailState = {}

export const errorPageReducer = createReducer<
  IReportErrorEmailState,
  ReportErrorEmailActionTypes
>(initialState)
  .handleAction(errorPageActions.reportError, (state) => ({
    ...state,
    loading: true
  }))
  .handleAction(errorPageActions.reportErrorSuccess, (state) => ({
    ...state,
    loading: false
  }))
  .handleAction(errorPageActions.reportErrorFailure, (state) => ({
    ...state,
    loading: false
  }))

const rootSelector = (state: AppState) => state.features.errorPage

export const getIsReportErrorEmailLoading = flow(
  rootSelector,
  ({ loading }) => loading
)

const onSubmit = function* (
  action: ReturnType<typeof errorPageActions.reportError>
) {
  const environment = yield* select(getEnvironmentName)
  const isProd = yield* select(getIsEnvironmentProduction)
  const currentUsername = yield* select(getRdotUsername)
  const subject = `spa Error Report ${!isProd ? ` - ${environment}` : ''}`
  const { error, errorInfo } = action.payload
  const body = yield* call(generateReportErrorEmail, {
    location: window.location.href,
    currentUsername,
    error,
    errorInfo,
    environment
  })

  const emailRequest: IGraphApiSendMailRequest = {
    message: {
      toRecipients: [{ emailAddress: { address: 'spafeedback@rockco.com' } }],
      ccRecipients: currentUsername
        ? [{ emailAddress: { address: currentUsername } }]
        : undefined,
      body: {
        contentType: 'HTML',
        content: body
      },
      subject
    }
  }

  try {
    const graphApiOptions = yield* call(getMicrosoftGraphApiOptions)
    yield* call(sendMail, graphApiOptions, emailRequest)
    yield put(errorPageActions.reportErrorSuccess())
  } catch (e: any) {
    yield put(errorPageActions.reportErrorFailure(e))
    return
  }
}

export const errorPageSagas = [
  () => takeLatest(errorPageActions.reportError, onSubmit)
]
