import {
  ComponentsStyles,
  createTheme,
  IPalette,
  IPanelStyles,
  ITextStyles
} from '@fluentui/react'

const textStyles: Partial<ITextStyles> = { root: { color: 'inherit' } }

const getPanelStyles = (palette: Partial<IPalette>): Partial<IPanelStyles> => {
  return {
    commands: { zIndex: 10, backgroundColor: palette.white },
    footer: { zIndex: 10, backgroundColor: palette.white },
    scrollableContent: { display: 'flex', flexDirection: 'column' },
    content: {
      [`@media (min-height: 0px)`]: {
        height: 'auto'
      }
    }
  }
}

const getComponentStyles = (palette: Partial<IPalette>): ComponentsStyles => ({
  Text: { styles: textStyles },
  Panel: { styles: getPanelStyles(palette) }
})

const darkPalette: Partial<IPalette> = {
  themePrimary: '#a9d3f2',
  themeLighterAlt: '#b3d9f4',
  themeLighter: '#bcddf5',
  themeLight: '#c5e2f6',
  themeTertiary: '#cee7f8',
  themeSecondary: '#d8ebf9',
  themeDarkAlt: '#e1f0fb',
  themeDark: '#ebf5fc',
  themeDarker: '#f4fafd',
  neutralLighterAlt: '#1c1c1c',
  neutralLighter: '#252525',
  neutralLight: '#343434',
  neutralQuaternaryAlt: '#3d3d3d',
  neutralQuaternary: '#454545',
  neutralTertiaryAlt: '#656565',
  neutralTertiary: '#c8c8c8',
  neutralSecondary: '#d0d0d0',
  neutralPrimaryAlt: '#dadada',
  neutralPrimary: '#ffffff',
  neutralDark: '#f4f4f4',
  black: '#000000',
  white: '#111111'
}

// Primary - #a9d3f2
// Text - #fff
// Background - #111
const darkTheme = createTheme({
  components: getComponentStyles(darkPalette),
  palette: darkPalette,
  effects: {
    elevation4:
      '0 1.6px 3.6px 0 rgba(80, 80, 80, 0.7), 0 0.3px 0.9px 0 rgba(80, 80, 80, 0.4)',
    elevation8:
      '0 3.2px 7.2px 0 rgba(80, 80, 80, 0.7), 0 0.6px 1.8px 0 rgba(80, 80, 80, 0.4)',
    elevation16:
      '0 6.4px 14.4px 0 rgba(80, 80, 80, 0.7), 0 1.2px 3.6px 0 rgba(80, 80, 80, 0.4)',
    elevation64:
      '0 25.6px 57.6px 0 rgba(80, 80, 80, 0.7), 0 4.8px 14.4px 0 rgba(80, 80, 80, 0.4)'
  },
  defaultFontStyle: { fontFamily: 'Inter' }
})

const lightPalette: Partial<IPalette> = {
  themePrimary: '#0078d4',
  themeLighterAlt: '#f3f9fd',
  themeLighter: '#d0e7f8',
  themeLight: '#a9d3f2',
  themeTertiary: '#5ca9e5',
  themeSecondary: '#1a86d9',
  themeDarkAlt: '#006cbe',
  themeDark: '#005ba1',
  themeDarker: '#004377',
  neutralLighterAlt: '#f8f8f8',
  neutralLighter: '#f4f4f4',
  neutralLight: '#eaeaea',
  neutralQuaternaryAlt: '#dadada',
  neutralQuaternary: '#d0d0d0',
  neutralTertiaryAlt: '#c8c8c8',
  neutralTertiary: '#bab8b7',
  neutralSecondary: '#a3a2a0',
  neutralPrimaryAlt: '#8d8b8a',
  neutralPrimary: '#323130',
  neutralDark: '#605e5d',
  black: '#000000',
  white: '#ffffff'
}

// default
const lightTheme = createTheme({
  components: getComponentStyles(lightPalette),
  palette: lightPalette,
  defaultFontStyle: { fontFamily: 'Inter' }
})

const chartColors = [
  '#13A5B0',
  '#B3E3F4',
  '#7BC46C',
  '#C4E1B2',
  '#B71F3A',
  '#EF4264',
  '#653C93',
  '#B02C91',
  '#F89938',
  '#F4B93F',
  '#45bda9'
]

export { darkTheme, lightTheme, chartColors }
