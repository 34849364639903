import { combineReducers } from 'redux'
import { errorPageReducer, errorPageSagas } from './ErrorPage'
import { reducer as listsReducer, sagas as listsSagas } from './Lists'

export const featureReducer = combineReducers({
  lists: listsReducer,
  errorPage: errorPageReducer
})
export type StaticFeaturesState = ReturnType<typeof featureReducer>

export const featureSagas = [...listsSagas, ...errorPageSagas]
