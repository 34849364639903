import React, { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Location, useLocation } from 'react-router-dom'
import { rdotUserActions } from '../../../store/user/rdotUser'
import { getRdotUserLoginError } from '../../../store/user/selectors'

export const LoginContainer: React.FC = () => {
  const location = useLocation()
  const { from } =
    (location.state as {
      from?: Location
    }) || {}

  const destination = from ? `${from.pathname}${from.search}${from.hash}` : '/'
  const loginError = useSelector(getRdotUserLoginError)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(
      rdotUserActions.loginRequested({ destinationAfterRedirect: destination })
    )
  }, [dispatch, destination])

  const resetAndLoginAgain = useCallback(
    (event?: React.MouseEvent) => {
      event?.preventDefault()
      dispatch(rdotUserActions.reset())
      dispatch(
        rdotUserActions.loginInteractive({
          destinationAfterRedirect: destination
        })
      )
    },
    [dispatch, destination]
  )

  const ErrorHeader = useMemo(
    (): React.FC => () =>
      (
        <span>
          An error occurred while logging into the application. Click{' '}
          <a href="/login" onClick={resetAndLoginAgain}>
            here
          </a>{' '}
          to try logging in again
        </span>
      ),
    [resetAndLoginAgain]
  )

  if (loginError?.message) {
    return (
      <div>
        <ErrorHeader />
        <div>{loginError?.message}</div>
      </div>
    )
  }

  if (loginError) {
    let messages: JSX.Element | JSX.Element[] = (
      <div>{JSON.stringify(loginError)}</div>
    )
    const errorKeys = Object.keys(loginError)
    if (errorKeys.length) {
      messages = errorKeys.map((key) => {
        return <div key={key}>{`${key}: ${(loginError as any)[key]}`}</div>
      })
    }
    return (
      <div>
        <ErrorHeader />
        <span>{messages}</span>
      </div>
    )
  }

  return <div className="spa-login-splash" />
}
