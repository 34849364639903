import { flow } from 'lodash/fp'
import { createSelector } from 'reselect'
import { call, delay, put, select, takeLatest } from 'typed-redux-saga'
import { ActionType, createAction, createReducer } from 'typesafe-actions'
import { AppState } from '..'
import { ISearchResult } from '../../api/common.types'
import {
  OdataFilterOperatorEnum,
  OdataPropertyFilterGroup
} from '../../api/odata'
import { notNullOrEmpty, stringArraysAreEqual } from '../../shared'
import { search } from '../shared/sagas'
import { accountContextUpdateActions } from './account'

const REQUEST = '@context/@client/REQUEST'
const START = '@context/@client/START'
const UPDATE = '@context/@client/UPDATE'
const ERROR = '@context/@client/ERROR'
const COMPLETE = '@context/@client/COMPLETE'

export interface IClientContextRequestPayload {
  legalEntityIds?: string[]
  clientAdvisorId?: string
}

export const clientContextUpdateActions = {
  request: createAction(REQUEST)<IClientContextRequestPayload | undefined>(),
  start: createAction(START)<IClientContextRequestPayload>(),
  success: createAction(UPDATE)<any[] | undefined>(),
  failure: createAction(ERROR)<Error>(),
  complete: createAction(COMPLETE)()
}

export type ClientContextActionTypes = ActionType<
  typeof clientContextUpdateActions
>

export interface IClientContextState {
  items?: any[]
  loading?: boolean
  error?: Error
}

const initialState: IClientContextState = {
  loading: false
}

export const clientContextReducer = createReducer<
  IClientContextState,
  ClientContextActionTypes
>(initialState)
  .handleAction(clientContextUpdateActions.start, () => ({
    ...initialState,
    loading: true
  }))
  .handleAction(clientContextUpdateActions.success, (state, action) => ({
    ...state,
    items: action.payload
  }))
  .handleAction(clientContextUpdateActions.failure, (state, action) => ({
    ...state,
    error: action.payload
  }))
  .handleAction(clientContextUpdateActions.complete, (state) => ({
    ...state,
    loading: false
  }))

export const getClientContext = (state: AppState) => state.context.client
export const getClientContextItems = flow(getClientContext, (x) => x.items)
export const getIsClientContextLoading = flow(
  getClientContext,
  (x) => x.loading
)
export const getClientContextError = flow(getClientContext, (x) => x.error)
export const getClientContextIds = createSelector(
  [getClientContextItems],
  (items) => items?.map((x) => x.LegalEntityID)
)

const requestClients = function* (
  action: ReturnType<typeof clientContextUpdateActions.request>
) {
  yield delay(25)

  console.debug('client context update requested', action.payload)

  if (
    !action.payload ||
    !action.payload.legalEntityIds?.length ||
    !action.payload.clientAdvisorId
  ) {
    yield put(clientContextUpdateActions.success(undefined))
    yield put(clientContextUpdateActions.complete())
    return
  }

  const currentClientIds: string[] | undefined = yield select(
    getClientContextIds
  )
  if (
    currentClientIds &&
    stringArraysAreEqual(currentClientIds, action.payload.legalEntityIds)
  ) {
    return
  }

  yield put(clientContextUpdateActions.start(action.payload))
}

function* fetchClients(
  action: ReturnType<typeof clientContextUpdateActions.start>
) {
  const filter: OdataPropertyFilterGroup = {
    and: [
      {
        operator: OdataFilterOperatorEnum.searchin,
        path: 'LegalEntityID',
        type: 'string',
        value: action.payload.legalEntityIds
      },
      {
        operator: OdataFilterOperatorEnum.eq,
        path: 'ClientAdvisorID',
        type: 'string',
        value: action.payload.clientAdvisorId
      }
    ]
  }

  try {
    const results: ISearchResult<any> = yield call(search, 'client' as const, {
      filters: [filter]
    })

    if (!results?.value) {
      throw new Error(`Client context is undefined for: ${action.payload}`)
    }

    yield put(clientContextUpdateActions.success(results.value))
  } catch (e: any) {
    yield put(clientContextUpdateActions.failure(e))
  }

  yield put(clientContextUpdateActions.complete())
}

export const clientSagas = [
  () => takeLatest(clientContextUpdateActions.request, requestClients),
  () => takeLatest(clientContextUpdateActions.start, fetchClients),
  () =>
    takeLatest(clientContextUpdateActions.start, function* () {
      yield put(accountContextUpdateActions.request(undefined))
    }),
  () =>
    takeLatest(clientContextUpdateActions.success, function* () {
      const clients: any[] | undefined = yield select(getClientContextItems)
      if (!clients?.length) {
        return
      }
      const accounts = clients
        .map((x) => x.Account)
        .flat()
        .filter(notNullOrEmpty)

      yield put(accountContextUpdateActions.request(accounts))
    })
]
